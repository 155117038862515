import React from "react"
import classnames from "classnames"
import styles from "./DefaultAvatar.module.scss"
import defaultAvatarBg from "images/default-avatar-bg.svg"

function initialsFromEmail(email?: string) {
  if (!email) return ""
  const names = email.split("@")?.[0]?.split(/\W/) ?? []
  if (names.length === 0) return ""
  return names.length > 1
    ? (names[0]?.[0] ?? "") + (names[names.length - 1]?.[0] ?? "")
    : names[0]?.[0] ?? ""
}

type DefaultAvatarProps = {
  email?: string
}

export default function DefaultAvatar({ email }: DefaultAvatarProps) {
  const initials = initialsFromEmail(email)

  return (
    <div className={styles.avatar}>
      <img src={defaultAvatarBg} alt="" />
      <div className={classnames(styles.initials, { [styles.small]: initials.length > 1 })}>
        {initials}
      </div>
    </div>
  )
}
