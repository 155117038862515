import React, { useCallback, useState } from "react"
import { format } from "date-fns"
import { FORMAT } from "consts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EventRecord } from "types"
import { add } from "ramda"
import styles from "./EventTable.module.scss"
import PayloadModal from "components/PayloadModal/PayloadModal"

type EventTableProps = {
  events: EventRecord[]
}

export default function EventTable({ events }: EventTableProps) {
  const [showCount, setShowCount] = useState(5)
  const [showPaylodModal, setShowPayloadModal] = useState(false)
  const [payloadToShow, setPayloadToShow] = useState("")
  const shownEvents = events.slice(0, showCount)
  const displayShowMoreButton = events.length > showCount && showCount < 30
  const isEmpty = events.length === 0

  const showMore = useCallback(() => setShowCount(add(5)), [])
  const showPayload = useCallback(payload => {
    setPayloadToShow(payload)
    setShowPayloadModal(true)
  }, [])
  const closeModal = useCallback(() => setShowPayloadModal(false), [])

  return isEmpty ? (
    <div className={styles.emptyMessage}>No recent events for this event type.</div>
  ) : (
    <div className={styles.payloads}>
      {showPaylodModal && <PayloadModal payload={payloadToShow} onClose={closeModal} />}
      <div className={styles.header}>
        <div className={styles.expand}>payload preview</div>
        <div>reception time</div>
      </div>
      <div className={styles.table}>
        <div className={styles.payloadColumn}>
          {shownEvents.map(({ payload }) => (
            <div key={JSON.stringify(payload)} className={styles.payload}>
              {JSON.stringify(payload, null, 1)}
            </div>
          ))}
        </div>
        <div className={styles.buttonColumn}>
          {shownEvents.map(({ payload }) => (
            <button
              key={JSON.stringify(payload)}
              className={styles.showPayloadButton}
              onClick={() => {
                showPayload(payload)
              }}
            >
              Show payload
            </button>
          ))}
        </div>
        <div className={styles.timestampColumn}>
          {shownEvents.map(({ payload, timestamp }) => (
            <div key={JSON.stringify(payload)} className={styles.timestamp}>
              {format(timestamp, FORMAT.EVENT_TIME_DATE)}
            </div>
          ))}
        </div>
      </div>
      {displayShowMoreButton && (
        <button className={styles.showMoreButton} onClick={showMore}>
          Show more
          <FontAwesomeIcon icon={["fas", "caret-down"]} className={styles.caretIcon} />
        </button>
      )}
    </div>
  )
}
