import React, { ComponentPropsWithRef, forwardRef, PropsWithChildren, Ref } from "react"
import classnames from "classnames"
import Button from "components/Button/Button"
import Card from "components/Card/Card"
import Expander from "components/Expander/Expander"
import useToggle from "hooks/useToggle"
import styles from "./EventTypeCard.module.scss"

type EventTypeCardProps = PropsWithChildren<{
  eventType: string
  expand?: boolean
}> &
  ComponentPropsWithRef<typeof Card>

const EventTypeCard = forwardRef(function EventTypeCard(
  { eventType, expand = false, children }: EventTypeCardProps,
  ref: Ref<HTMLDivElement>,
) {
  const [isExpanded, toggleIsExpanded] = useToggle(expand)

  return (
    <Card className={classnames(styles.container, { [styles.isExpanded]: isExpanded })} ref={ref}>
      <div className={styles.titleBar} onClick={toggleIsExpanded}>
        <div className={styles.title}>{eventType}</div>
        <Button
          color="text_main"
          kind="text-only"
          icon={["fas", "chevron-down"]}
          iconProps={{ flip: isExpanded ? "vertical" : undefined }}
          size="xl"
          data-testid="expand-button"
        />
      </div>
      <Expander isExpanded={isExpanded}>
        {/* TODO: fix expander bug, remove the expanderContents div */}
        <div className={styles.expanderContents}>{children}</div>
      </Expander>
    </Card>
  )
})

export default EventTypeCard
