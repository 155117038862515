import React, { useCallback } from "react"
import styles from "./ResetPassword.module.scss"
import LandingLayout from "components/LandingLayout/LandingLayout"
import { getRoutePath } from "services/routes"
import { Link, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import Api from "services/api"
import toast from "services/toast"
import { FORM, NOTIFS } from "consts"
import TextInput from "components/TextInput/TextInput"
import { isValidEmail } from "helpers/validation"
import Button from "components/Button/Button"

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({ mode: "onTouched" })
  const history = useHistory()

  const sendResetEmail = useCallback(
    async ({ email }) => {
      const response = await Api.userAuth.sendResetPasswordEmail(email)
      if (response) {
        toast.success(NOTIFS.SUCCESS.FORGOT_PASSWORD_EMAIL_SENT, { autoClose: 6000 })
        history.push(getRoutePath("login"))
      }
    },
    [history],
  )

  return (
    <LandingLayout>
      <form className={styles.form} onSubmit={handleSubmit(sendResetEmail)}>
        <h1>Reset password</h1>
        <TextInput
          type="email"
          name="email"
          className={styles.input}
          ref={register({ validate: isValidEmail, required: FORM.FIELD_ERROR.REQUIRED })}
          label="E-mail"
          reserveSpace
          error={errors.email}
          size="lg"
        />
        <Button fullWidth type="submit" isLoading={isSubmitting} size="lg">
          reset password
        </Button>
      </form>
      <Link to={getRoutePath("login")} className={styles.linkUnderForm}>
        back to login
      </Link>
    </LandingLayout>
  )
}
