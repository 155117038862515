import React, { useState } from "react"
import styles from "./CreateUserModal.module.scss"
import Modal from "components/Modal/Modal"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import TextInput from "components/TextInput/TextInput"
import Button from "components/Button/Button"
import { FORM } from "consts"
import { isValidEmail } from "helpers/validation"
import DefaultAvatar from "components/DefaultAvatar/DefaultAvatar"

export type FormData =
  | { invite: true; users: { email: string }[] }
  | { invite: false; users: { email: string; password: string }[] }

type CreateUserModalProps = {
  onClose: () => void
  onSubmit: (formData: FormData) => Promise<void>
}

export default function CreateUserModal({ onClose, onSubmit }: CreateUserModalProps) {
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({
    mode: "onTouched",
    defaultValues: { users: [{ email: "", password: "" }] },
  })
  const { fields, append, remove } = useFieldArray({ control, name: "users" })
  const [invite, setInvite] = useState(false)
  const form = watch()

  return (
    <Modal className={styles.container} onClose={onClose} data-testid="create-user-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.header}>
          <div className={styles.title}>create user</div>
          <Controller
            control={control}
            name="invite"
            defaultValue={invite}
            render={({ ref, onChange }) => (
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  ref={ref}
                  onChange={e => {
                    setValue("invite", e.target.checked)
                    setInvite(e.target.checked)
                  }}
                  data-testid="invite-checkbox"
                />{" "}
                Send invitation e-mails
              </label>
            )}
          />
        </div>
        {fields.map((field, i) => (
          <div key={field.id} className={styles.row}>
            <DefaultAvatar email={form.users?.[i]?.email} />
            <TextInput
              name={`users[${i}].email`}
              ref={register({ required: FORM.FIELD_ERROR.REQUIRED, validate: isValidEmail })}
              label="E-mail"
              reserveSpace
              className={styles.input}
              error={errors.users?.[i]?.email}
              type="email"
              defaultValue={field.email}
            />
            {!invite && (
              <TextInput
                name={`users[${i}].password`}
                ref={register({ required: FORM.FIELD_ERROR.REQUIRED })}
                label="Password"
                reserveSpace
                className={styles.input}
                error={errors.users?.[i]?.password}
                type="password"
                autoComplete="off"
                defaultValue={field.password}
                showPasswordStrength
              />
            )}
            {fields.length > 1 && (
              <Button
                kind="text-only"
                icon={["fas", "trash-alt"]}
                onClick={() => remove(i)}
                color="red"
                faded
                type="button"
              />
            )}
          </div>
        ))}
        <Button
          onClick={append}
          icon={["fas", "plus"]}
          color="me_primary"
          kind="text-only"
          type="button"
        >
          add another user
        </Button>
        <div className={styles.buttons}>
          <div className={styles.expand}>
            <Button
              onClick={onClose}
              type="button"
              kind="text-only"
              color="grey_7"
              disabled={isSubmitting}
            >
              cancel
            </Button>
          </div>
          <Button type="submit" isLoading={isSubmitting}>
            save
          </Button>
        </div>
      </form>
    </Modal>
  )
}
