import "./fontAwesomeImports"

import React, { useCallback, useEffect } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { getRoutePath } from "services/routes"
import { ToastContainer } from "react-toastify"
import Login from "pages/Login/Login"
import UIKit from "pages/UIKit/UIKit"
import useTypedSelector from "hooks/useTypedSelector"
import "react-toastify/dist/ReactToastify.css"
import "./toastify.scss"
import Logout from "pages/Logout/Logout"
import ResetPassword from "pages/ResetPassword/ResetPassword"
import SetPassword from "pages/SetPassword/SetPassword"
import OktaCallback from "pages/OktaCallback/OktaCallback"
import history from "services/history"
import AuthenticatedRoutes from "./components/AuthenticatedRoutes/AuthenticatedRoutes"

export default function App() {
  const alertEdits = useTypedSelector(state => state.alertEdits)
  const hasUnsavedAlerts = Object.values(alertEdits).length > 0
  const warnBeforeUnload = useCallback(
    event => {
      if (hasUnsavedAlerts) {
        event.preventDefault()
        event.returnValue = ""
      }
    },
    [hasUnsavedAlerts],
  )

  useEffect(() => {
    window.addEventListener("beforeunload", warnBeforeUnload)
    return () => window.removeEventListener("beforeunload", warnBeforeUnload)
  }, [warnBeforeUnload])

  return (
    <Router history={history}>
      <div data-testid="app">
        <Switch>
          <Route path="/__uikit" exact component={UIKit} />
          <Route path={getRoutePath("login")} component={Login} />
          <Route path={getRoutePath("logout")} component={Logout} />
          <Route path={getRoutePath("oktaCallback")} component={OktaCallback} />
          <Route path={getRoutePath("password.reset")} component={ResetPassword} />
          <Route path={getRoutePath("password.set")} component={SetPassword} />
          <Route component={AuthenticatedRoutes} />
        </Switch>
        <ToastContainer autoClose={2500} hideProgressBar />
      </div>
    </Router>
  )
}
