import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/Button/Button"
import Spinner from "components/Spinner/Spinner"
import TextInput from "components/TextInput/TextInput"
import React from "react"
import styles from "./UIKit.module.scss"

export default function UIKit() {
  return (
    <div className={styles.container}>
      <h2>Button</h2>
      <div className={styles.buttons}>
        <Button>button</Button>
        <Button isLoading>loading</Button>
        <Button disabled>disabled</Button>
        <Button disabled isLoading>
          dis load
        </Button>
        <Button icon={["fas", "check"]}>w icon</Button>
        <Button icon={["fas", "check"]} iconOnRight>
          w icon on right
        </Button>
        <Button icon={["fas", "check"]}></Button>
        <Button icon={["fas", "check"]} iconProps={{ spin: true }}>
          w icon props
        </Button>
        <Button kind="secondary">secondary</Button>
        <Button kind="secondary" disabled>
          secondary disabled
        </Button>
        <Button kind="secondary" icon={["fas", "check"]} />
        <Button kind="text-only">minimal</Button>
        <div className={styles.coloredBg}>
          <Button kind="text-only" color="text_main">
            minimal on colored bg
          </Button>
        </div>
        <Button kind="text-only" disabled>
          minimal disabled
        </Button>
        <Button kind="text-only" icon={["fas", "check"]} />
        <Button kind="text-only" faded>
          minimal faded
        </Button>
        <Button kind="text-only" faded icon={["far", "eye-slash"]} color="grey_9" />
        <Button size="lg">lg</Button>
        <Button size="lg" icon={["fas", "check"]}>
          lg w icon
        </Button>
        <Button size="lg" icon={["fas", "check"]} />
        <Button size="xl">xl</Button>
        <Button size="xl" icon={["fas", "check"]}>
          xl w icon
        </Button>
        <Button size="xl" icon={["fas", "check"]} />
        <Button color="grey_9">color grey 9</Button>
        <Button color="grey_9" kind="secondary">
          color grey 9 sec
        </Button>
        <Button color="red">color red</Button>
        <Button color="red" kind="secondary">
          color red sec
        </Button>
        <Button color="green">color green</Button>
        <Button color="green" kind="secondary">
          color green sec
        </Button>
        <Button fullWidth>fullwidth</Button>
        <Button kind="text-only" isLoading icon={["fas", "check"]} />
        <Button kind="text-only" icon={["fas", "check"]} />
        <Button kind="text-only" faded color="text_main" icon={["fas", "pencil-alt"]} size="xl" />
      </div>
      <h2>Tooltip</h2>
      <div className={styles.spinners}>
        <div data-tooltip="Tooltip content">div with tooltip</div>
        <Button data-tooltip="Tooltip content">button with tooltip</Button>
        <span>icon with tooltip:</span>
        <div data-tooltip="Tooltip content">
          <FontAwesomeIcon icon={["fas", "trash-alt"]} />
        </div>
      </div>
      <h2>Spinner</h2>
      <div className={styles.spinners}>
        <Spinner />
        <Spinner className={styles.primaryColor} />
        <Spinner size="sm" />
      </div>
      <h2>TextInput</h2>
      <div className={styles.inputs}>
        <TextInput />
        <TextInput style={{ width: "100px" }} />
        <TextInput label="Input with label" />
        <TextInput label="Input reserving space for error message" reserveSpace />
        <TextInput label="Input with error" error={true} />
        <TextInput label="Input with error message" error={{ message: "Required" }} />
        <TextInput
          label="Input with error message & reserved space"
          error={{ message: "Required" }}
          reserveSpace
        />
        <TextInput error={{ message: "Required" }} reserveSpace />
        <TextInput type="password" reserveSpace />
        <TextInput type="password" />
        <TextInput type="password" label="Label" />
        <TextInput type="password" label="Label" reserveSpace />
        <TextInput type="password" label="Label" size="lg" />
        <TextInput type="password" label="Label" size="lg" reserveSpace />
      </div>
    </div>
  )
}
