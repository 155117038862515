import React from "react"
import styles from "./Logout.module.scss"
import handPic from "images/waving-hand.svg"
import meiroLogoutPic from "images/meiro-logout.png"
import Button from "components/Button/Button"
import { Link } from "react-router-dom"
import { getRoutePath } from "services/routes"

export default function Logout() {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <img src={handPic} className={styles.handPic} alt="" />
        <div className={styles.mainText}>Hey there! Thanks for using Meiro.</div>
        <div className={styles.mainTextColored}>We love having you around!</div>
        <div className={styles.secondaryText}>
          For exciting updates, check out our{" "}
          <a href="https://www.meiro.io/blog/" target="_new">
            blog
          </a>
          .
        </div>
        <Link to={getRoutePath("login")}>
          <Button size="lg" className={styles.loginButton}>
            login
          </Button>
        </Link>
      </div>
      <div className={styles.logoutPicWrapper}>
        <img src={meiroLogoutPic} className={styles.logoutPic} alt="" />
      </div>
    </div>
  )
}
