import React, { ComponentProps, useMemo, useRef } from "react"
import classnames from "classnames"
import styles from "./EventChart.module.scss"
import { EventTypeCounts } from "types"
import { Line } from "react-chartjs-2"
import { reverse } from "ramda"
import { ChartTooltipModel, ChartTooltipModelBody, InteractionMode } from "chart.js"
import { format, subMinutes } from "date-fns"

type EventChartProps = {
  eventCounts: EventTypeCounts[]
} & ComponentProps<"div">

const labelColor = "#777777"
const colors = [
  ["#2D4B86", "#2258C3", "#6C92E0"], // blue 1
  ["#BF4040", "#CC6677", "#D27979"], // pinkish red
  ["#7F9D43", "#8CBA5E", "#99CC66"], // green 1
  ["#E88C30", "#CC8033", "#CC9966"], // brown
  ["#2929A3", "#5959A6", "#8484AD"], // purple 1
  ["#2D7E86", "#22B6C3", "#6CD6E0"], // teal
  ["#E05252", "#F07575", "#F5A3A3"], // orangish red
  ["#17823B", "#1AB24D", "#5CD685"], // green 2
  ["#CCAC00", "#E5C100", "#F5D73D"], // yellow
  ["#556CDB", "#8191E4", "#ABB6ED"], // cornflower blue
]

const chartOptionsBase = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          stepSize: 1,
          fontSize: 10,
          fontStyle: "bold",
          fontColor: labelColor,
          maxTicksLimit: 10,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          maxRotation: 0,
          autoSkipPadding: 30,
          fontSize: 10,
          fontStyle: "bold",
          fontColor: labelColor,
        },
        gridLines: { display: false },
      },
    ],
  },
  legend: {
    labels: {
      usePointStyle: true,
      boxWidth: 8,
      fontSize: 10,
      fontStyle: "bold",
      fontColor: labelColor,
    },
  },
  hover: {
    mode: "index" as InteractionMode,
    intersect: false,
  },
}

const commonLineOptions = {
  borderWidth: 2,
  lineTension: 0,
  pointRadius: 0,
  pointHoverRadius: 4,
  pointHitRadius: 4,
}

export default function EventChart({ className, eventCounts }: EventChartProps) {
  const chartRef = useRef<Line>(null)

  const chartOptions = {
    ...chartOptionsBase,
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,
      mode: "index" as InteractionMode,
      intersect: false,
      position: "nearest",
      custom: function (tooltipModel: ChartTooltipModel) {
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip")

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div")
          tooltipEl.id = "chartjs-tooltip"
          tooltipEl.style.backgroundColor = "rgba(0,0,0,0.8)"
          tooltipEl.style.borderRadius = "6px"
          tooltipEl.style.color = "#ffffff"
          tooltipEl.innerHTML = "<table></table>"
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = "0"
          return
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform")
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add("no-transform")
        }

        function getBody(bodyItem: ChartTooltipModelBody) {
          return bodyItem.lines
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || []
          const bodyLines = tooltipModel.body.map(getBody)

          let innerHtml = "<thead>"

          titleLines.forEach(function (title) {
            innerHtml += "<tr><th style='text-align:left;'>" + title + "</th></tr>"
          })
          innerHtml += "</thead><tbody>"

          bodyLines.forEach(function (body, i) {
            // mistyped in library
            const colors = (tooltipModel.labelColors[i] as unknown) as {
              backgroundColor: string
              borderColor: string
            }
            let style = "background:" + colors.backgroundColor
            style += "; border-color:" + colors.borderColor
            style += "; border-width: 2px"
            style += "; width: 9px; height: 9px; display: inline-block; margin-right: 4px;"
            const span = '<span style="' + style + '"></span>'
            innerHtml += "<tr><td>" + span + body + "</td></tr>"
          })
          innerHtml += "</tbody>"

          const tableRoot = tooltipEl.querySelector("table")
          tableRoot!.innerHTML = innerHtml
        }

        // `this` will be the overall tooltip
        if (chartRef && chartRef.current) {
          const position = chartRef.current.chartInstance.canvas!.getBoundingClientRect()
          const offset = 8
          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1"
          tooltipEl.style.position = "absolute"
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + offset + "px"
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY - offset + "px"
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px"
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
          tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px"
          tooltipEl.style.pointerEvents = "none"
        }
      },
    },
  }

  const data = useMemo(
    () => ({
      datasets: eventCounts.map(({ eventType, countPerMinute }, i) => ({
        label: eventType.toUpperCase(),
        data: reverse(countPerMinute),
        backgroundColor: colors[i % colors.length]![2],
        borderColor: colors[i % colors.length]![1],
        ...commonLineOptions,
      })),
      labels: Array.from({ length: 60 }).map((_, i) =>
        format(subMinutes(new Date(), 60 - i), "HH:mm"),
      ),
    }),
    [eventCounts],
  )
  const isEmpty = eventCounts.length === 0

  return (
    <div className={classnames(styles.container, className)} data-testid="event-chart">
      {isEmpty ? (
        <div className={styles.emptyMessage}>No events in the last 60 minutes.</div>
      ) : (
        <Line data={data} options={chartOptions} ref={chartRef} />
      )}
    </div>
  )
}
