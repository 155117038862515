import React, { ComponentProps, forwardRef, Ref } from "react"
import classnames from "classnames"
import styles from "./Card.module.scss"

type CardBgColor = "white" | "light_blue_bg"
type CardProps = {
  bgColor?: CardBgColor
} & ComponentProps<"div">

const Card = forwardRef(function Card(
  { className, bgColor = "white", children }: CardProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div
      className={classnames(
        styles.card,
        styles[("color_" + bgColor) as keyof typeof styles],
        className,
      )}
      ref={ref}
    >
      {children}
    </div>
  )
})

export default Card
