import React, { PropsWithChildren } from "react"
import styles from "./Layout.module.scss"
import Header from "components/Header/Header"

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.childrenWrapper}>{children}</div>
    </div>
  )
}
