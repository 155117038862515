import React, { ComponentProps, forwardRef, Ref, useCallback, useState } from "react"
import classnames from "classnames"
import styles from "./TextInput.module.scss"
import Button from "components/Button/Button"
import useToggle from "hooks/useToggle"
import WeakIcon from "./weakIcon.svg"
import MildIcon from "./mildIcon.svg"
import StrongIcon from "./strongIcon.svg"
import zxcvbn from "zxcvbn"

type TextInputProps = {
  label?: string
  error?: { message?: string } | boolean
  reserveSpace?: boolean
  size?: "md" | "lg"
  showPasswordStrength?: boolean
} & Omit<ComponentProps<"input">, "size">

const zxcvbnScoreToLabel = ["weak", "weak", "fine", "strong", "strong"] as const
const passwordStrengthIcon = { weak: WeakIcon, fine: MildIcon, strong: StrongIcon }

const TextInput = forwardRef(function TextInput(
  {
    label,
    error,
    reserveSpace = false,
    style,
    className,
    type,
    size = "md",
    showPasswordStrength,
    onChange,
    value,
    ...props
  }: TextInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const errorMessage = typeof error === "object" && error.message

  const [showPassword, toggleShowPassword] = useToggle(false)
  const [passwordStrength, setPasswordStrength] = useState<"weak" | "fine" | "strong">("weak")
  const [isInputEmpty, setIsInputEmpty] = useState(!value)
  const inputType = type === "password" ? (showPassword ? "text" : "password") : type

  const onInputChange = useCallback(
    e => {
      if (showPasswordStrength) {
        const { value } = e.target
        const passwordScore = (value && zxcvbn(value.toString()).score) || 0
        const passwordStrength = zxcvbnScoreToLabel[passwordScore]
        setPasswordStrength(passwordStrength)
        setIsInputEmpty(!value)
      }
      onChange?.(e)
    },
    [onChange, showPasswordStrength],
  )

  return (
    <div
      className={classnames(
        styles.container,
        { [styles.hasSpacer]: reserveSpace },
        styles[("size_" + size) as keyof typeof styles],
        className,
      )}
      style={style}
    >
      <div className={styles.flexWrapper}>
        <label className={styles.labelWrapper}>
          {label && <div className={styles.label}>{label}</div>}
          <input
            ref={ref}
            type={inputType}
            className={classnames(styles.input, { [styles.hasError]: error })}
            onChange={onInputChange}
            value={value}
            {...props}
          />
          {type === "password" && (
            <Button
              icon={showPassword ? ["far", "eye"] : ["far", "eye-slash"]}
              className={styles.eyeButton}
              kind="text-only"
              faded
              color="grey_9"
              onClick={toggleShowPassword}
              type="button"
              size="xl"
            />
          )}
        </label>
        {showPasswordStrength && type === "password" && (
          <div
            className={classnames(styles.passwordStrength, {
              [styles[passwordStrength]]: passwordStrength,
              [styles.isEmpty]: isInputEmpty,
            })}
          >
            <img src={passwordStrengthIcon[passwordStrength]} alt={passwordStrength} />
            <div>{passwordStrength}</div>
          </div>
        )}
      </div>

      {reserveSpace && <div className={styles.spacer}></div>}
      {errorMessage && (
        <div className={styles.errorMessage} data-testid="error-message">
          {errorMessage}
        </div>
      )}
    </div>
  )
})

export default TextInput
