import { FORM } from "consts"
import zxcvbn from "zxcvbn"

export const isValidEmail = (email: string) => {
  return (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/.test(
      email.trim(),
    ) || FORM.FIELD_ERROR.INVALID_EMAIL
  )
}

export const isValidRegex = (input: string) => {
  try {
    new RegExp(input)
    return true
  } catch {
    return FORM.FIELD_ERROR.INVALID_REGEX
  }
}

export const isStrongPassword = (password: string) =>
  zxcvbn(password).score > 1 || FORM.FIELD_ERROR.WEAK_PASSWORD
