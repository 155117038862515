export const routes = {
  login: "/login",
  logout: "/logout",
  oktaCallback: "/okta_login",
  userSettings: "/user_settings",
  users: "/users",
  "users.detail": "/users/:id",
  "password.reset": "/reset_password",
  "password.set": "/set_password",
  monitoring: "/monitoring",
  "monitoring.detail": "/monitoring/sources/:source",
  "monitoring.alerts": "/monitoring/alerts",
  trash: "/trash",
}

interface Params {
  [key: string]: string
}

const replaceParamsInRoutePath = (path: string, params: Params) => {
  const paramsKeys = Object.keys(params)
  if (!paramsKeys.length) {
    return path
  }

  paramsKeys.forEach(property => {
    const reg = new RegExp(`:${property}(?![\\w\\d])`, "i")
    path = path.replace(reg, params[property]!)
  })

  return path
}

export const getRoutePath = (name: keyof typeof routes, params: Params = {}) =>
  replaceParamsInRoutePath(routes[name], params)
