import React, { ComponentProps } from "react"
import styles from "./SourceOverview.module.scss"
import Card from "components/Card/Card"
import { Link } from "react-router-dom"
import { getRoutePath } from "services/routes"
import EventTypeRow from "../EventTypeRow/EventTypeRow"
import Button from "components/Button/Button"
import { EventTypeCounts, EventTypeEvents, Source } from "types"
import EventChart from "components/EventChart/EventChart"
import { sourcePaths } from "consts"

type SourceOverviewProps = {
  sourceSlug: Source
  sourceName?: string
  sourceIconUrl?: string
  events: EventTypeEvents[]
  eventCounts: EventTypeCounts[]
} & ComponentProps<"div">

export default function SourceOverview({
  className,
  sourceName,
  sourceSlug,
  sourceIconUrl,
  events,
  eventCounts,
}: SourceOverviewProps) {
  const isEmpty = events.length === 0

  return (
    <div className={className} data-testid={`source-${sourceSlug}`}>
      <Card className={styles.titleCard} bgColor="light_blue_bg">
        {sourceIconUrl && <img src={sourceIconUrl} alt="" />}
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{sourceName ?? sourceSlug}</h1>
          <div className={styles.path}>{sourcePaths[sourceSlug]}</div>
        </div>
        {isEmpty && <div className={styles.emptyMessage}>No recent events</div>}
        <Link to={getRoutePath("monitoring.detail", { source: sourceSlug })}>
          <Button>detail</Button>
        </Link>
      </Card>
      {!isEmpty && (
        <Card className={styles.mainCard}>
          <EventChart eventCounts={eventCounts} />
          <div className={styles.eventTypesHeader}>
            <div className={styles.expand}>event type</div>
            <div>reception time</div>
          </div>
          {events.map(eventTypeObject => (
            <EventTypeRow
              events={eventTypeObject}
              className={styles.eventTypeRow}
              key={eventTypeObject.eventType}
            />
          ))}
        </Card>
      )}
    </div>
  )
}
