import React, { ComponentProps, ReactNode } from "react"
import classnames from "classnames"
import { IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import styles from "./Button.module.scss"
import Spinner from "components/Spinner/Spinner"

type ButtonColor =
  | "me_primary"
  | "white"
  | "grey_E"
  | "grey_C"
  | "grey_A"
  | "grey_9"
  | "grey_7"
  | "grey_5"
  | "red"
  | "green"
  | "yellow"
  | "blue"
  | "text_main"
type ButtonSize = "sm" | "md" | "lg" | "xl"
type ButtonKind = "primary" | "secondary" | "text-only"

type ButtonPropsBase = {
  color?: ButtonColor
  size?: ButtonSize
  secondary?: boolean
  faded?: boolean
  kind?: ButtonKind
  icon?: [IconPrefix, IconName]
  iconOnRight?: boolean
  iconProps?: Omit<FontAwesomeIconProps, "icon">
  fullWidth?: boolean
  isLoading?: boolean
} & Omit<ComponentProps<"button">, "size">

type ButtonPropsWithChildren = ButtonPropsBase & {
  children: ReactNode
}

// `icon` required if no children
type ButtonPropsWithoutChildren = ButtonPropsBase & {
  icon: [IconPrefix, IconName]
}

type ButtonProps = ButtonPropsWithChildren | ButtonPropsWithoutChildren

export default function Button({
  children,
  icon,
  iconOnRight,
  iconProps,
  className,
  size = "md",
  color = "me_primary",
  fullWidth,
  isLoading,
  faded,
  kind,
  disabled,
  ...props
}: ButtonProps) {
  return (
    <button
      className={classnames(
        styles.button,
        className,
        styles[("color_" + color) as keyof typeof styles],
        styles[("size_" + size) as keyof typeof styles],
        {
          [styles.withIcon]: icon,
          [styles.iconOnly]: !children,
          [styles.iconOnRight]: iconOnRight,
          [styles.fullWidth]: fullWidth,
          [styles.isLoading]: isLoading,
          [styles.disabled]: disabled,
          [styles.secondaryButton]: kind === "secondary",
          [styles.textOnly]: kind === "text-only",
          [styles.faded]: faded,
        },
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && (
        <Spinner size={kind === "text-only" ? "xs" : "sm"} className={styles.spinner} />
      )}
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          {...iconProps}
          className={classnames(styles.icon, iconProps?.className)}
        />
      )}
      {children && <div className={styles.childrenWrapper}>{children}</div>}
    </button>
  )
}
