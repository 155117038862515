import React, { ComponentProps } from "react"
import classnames from "classnames"
import styles from "./Chip.module.scss"
import Button from "components/Button/Button"

type ChipProps = {
  color?: "me_primary"
  onDelete?: () => void
} & ComponentProps<"div">

export default function Chip({ className, color = "me_primary", children, onDelete }: ChipProps) {
  return (
    <div
      className={classnames(
        styles.chip,
        styles[("color_" + color) as keyof typeof styles],
        className,
      )}
    >
      {children}
      {onDelete && (
        <Button kind="text-only" faded color="white" onClick={onDelete} icon={["fas", "times"]} />
      )}
    </div>
  )
}
