export const STORAGE_KEYS = {
  REDIRECT_AFTER_OKTA: "redirectAfterOktaLogin",
  TERMS_ACCEPTED: "termsAccepted",
}

export const FORM = {
  FIELD_ERROR: {
    REQUIRED: "Required",
    INVALID_EMAIL: "Invalid e-mail address",
    INVALID_REGEX: "Invalid regular expression",
    WEAK_PASSWORD: "Password too weak",
  },
}

export const FORMAT = {
  EVENT_TIME_DATE: "HH:mm:ss d.M.y",
}

export const sources = [
  "sdk",
  "mobile",
  "fb",
  "appsflyer",
  "appsflyer_v2",
  "netcore",
  "tradedesk",
  "tradedesk_sync",
  "adjust",
  "mailchimp",
  "smartemailing",
  "magnews",
  "yellow_ai",
  "klaviyo",
  "insider",
  "emails",
  "test",
  "kb_ib",
  "fat_zebra",
  "stripe",
  "kochava",
  "snowplow",
  "active_campaign",
  "meiro_mobile",
  "algolia",
  "n8n",
] as const

export const sourcePaths: Record<typeof sources[number], string> = {
  sdk: "/",
  mobile: "/mobile",
  fb: "/fb",
  appsflyer: "/appsflyer",
  appsflyer_v2: "/appsflyerv2",
  netcore: "/netcore",
  tradedesk: "/tradedesk",
  tradedesk_sync: "/tradedesk_sync",
  adjust: "/adjust",
  mailchimp: "/mailchimp",
  smartemailing: "/smartemailing",
  magnews: "/magnews",
  yellow_ai: "/yellow_ai",
  klaviyo: "/klaviyo",
  insider: "/insider",
  emails: "/emails",
  test: "/test",
  kb_ib: "/kb_ib",
  fat_zebra: "/fat_zebra",
  stripe: "/stripe",
  kochava: "/kochava",
  snowplow: "/snowplow",
  active_campaign: "/active_campaign",
  meiro_mobile: "/meiro_mobile",
  algolia: "/algolia",
  n8n: "/n8n",
} as const

export const NOTIFS = {
  SUCCESS: {
    ALERT_CREATED: "Alert saved.",
    ALERT_MODIFIED: "Changes saved.",
    ALERT_DELETED: "Alert deleted.",
    USER_CREATED: "User(s) created.",
    USER_INVITED: "User(s) have been invited.",
    USER_MODIFIED: "User changes saved.",
    USER_DELETED: "User deleted.",
    USER_RESTORED: "User restored.",
    COPIED_TO_CB: "Copied to clipboard.",
    FORGOT_PASSWORD_EMAIL_SENT:
      "We have sent you an e-mail with instructions. If you do not receive an e-mail, please contact your administrator.",
    PASSWORD_SET: "Your password has been set.",
    ACCOUNT_CREATED: "Your account has been created. You can now log in!",
    PASSWORD_CHANGED: "Your password has been changed.",
  },
  ERROR: {
    NETWORK_ERROR: "Couldn't connect to server. Are you connected to the internet?",
    SERVER_ERROR:
      "Internal server error. Please try again; if the problem persists, contact help@meiro.io.",
    INVALID_LOGIN: "Wrong password or email.",
    WRONG_PASSWORD: "Wrong password.",
    UNAUTHORIZED: "Invalid credentials.",
  },
  INFO: {
    TOKEN_EXPIRED: "Your session has expired. Please log in again.",
  },
}

const isProduction = process.env.NODE_ENV === "production"

// placeholders are replaced during production build by replace_placeholders.py
export const API_URL = isProduction
  ? "[[API_URI]]"
  : window.location.origin.replace("app", "api").concat("/api/")
export const WS_URL = isProduction ? "[[WS_URI]]" : window.location.origin.replace("app", "stats")

export const MEIRO_LOCATION = isProduction
  ? "[[MEIRO_LOCATION]]"
  : process.env.REACT_APP_MEIRO_LOCATION ?? ""
export const MEIRO_VERSION = isProduction
  ? "[[MEIRO_VERSION]]"
  : process.env.REACT_APP_MEIRO_VERSION ?? ""
export const CLIENT_NAME = isProduction
  ? "[[MEIRO_CLIENT]]"
  : process.env.REACT_APP_CLIENT_NAME ?? ""

const usersEnabledStr = isProduction
  ? "[[USERS_ENABLED]]"
  : process.env.REACT_APP_USERS_ENABLED ?? "1"
export const USERS_ENABLED = parseInt(usersEnabledStr) === 1
