import React, { useCallback, useEffect, useState } from "react"
import classnames from "classnames"

import styles from "./Header.module.scss"
import clientLogo from "images/client.svg"
import meiroLogo from "images/meiro-symbol-white-transparent.svg"
import { Link, NavLink, Route, Switch, useHistory } from "react-router-dom"
import { getRoutePath } from "services/routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useToggle from "hooks/useToggle"
import AuthService from "services/auth"
import { CLIENT_NAME, MEIRO_LOCATION, MEIRO_VERSION, USERS_ENABLED } from "consts"
import DefaultAvatar from "components/DefaultAvatar/DefaultAvatar"
import Spinner from "components/Spinner/Spinner"
import useFetcher from "hooks/useFetcher"
import Api from "services/api"
import { format } from "date-fns"

export default function Header() {
  const [isUserMenuOpen, toggleUserMenu] = useToggle(false)
  const history = useHistory()
  const user = AuthService.getCurrentUser()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [buildDate, setBuildDate] = useState("")

  const { fetch: fetchSystemInfo } = useFetcher(Api.systemInfo.getSystemInfo)

  useEffect(() => {
    fetchSystemInfo().then(systemInfo => setBuildDate(systemInfo?.build_date ?? ""))
  }, [fetchSystemInfo])

  const logOut = useCallback(async () => {
    setIsLoggingOut(true)
    await AuthService.logout()
    history.push(getRoutePath("logout"))
  }, [history])

  return (
    <div className={styles.container}>
      <div className={styles.primaryBar}>
        <div className={styles.primaryBarInner}>
          <div className={styles.left}>
            <Link to={getRoutePath("monitoring")} className={styles.titleWrapper}>
              <img src={meiroLogo} alt="Meiro logo" className={styles.meiroLogo} />
              <img src={clientLogo} alt="client logo" className={styles.clientLogo} />
              <div className={styles.title}>
                meiro
                <br />
                events for
                <br />
                <strong>{CLIENT_NAME}</strong>
              </div>
            </Link>
          </div>
          <div className={styles.middle}>
            <NavLink
              to={getRoutePath("monitoring")}
              className={styles.primaryLink}
              activeClassName={styles.active}
            >
              <div className={styles.iconWrapper}>
                <FontAwesomeIcon icon={["fas", "tachometer"]} className={styles.icon} />
              </div>
              <div>monitoring</div>
            </NavLink>
          </div>
          <div className={styles.right}>
            {USERS_ENABLED && (
              <NavLink
                to={getRoutePath("users")}
                activeClassName={styles.active}
                className={styles.adminLink}
              >
                <div data-tooltip="User administration">
                  <FontAwesomeIcon icon={["fas", "wrench"]} />
                </div>
              </NavLink>
            )}
            <NavLink
              to={getRoutePath("trash")}
              activeClassName={styles.active}
              className={styles.adminLink}
            >
              <div data-tooltip="Trash">
                <FontAwesomeIcon icon={["fas", "trash-alt"]} />
              </div>
            </NavLink>

            <div className={styles.userMenuContainer}>
              <button
                className={classnames(styles.userMenuButton, { [styles.isOpen]: isUserMenuOpen })}
                onClick={toggleUserMenu}
              >
                <DefaultAvatar email={user?.email} />
                <FontAwesomeIcon icon={["fas", "caret-down"]} />
              </button>
              {isUserMenuOpen && (
                <>
                  <div className={styles.clickCatcher} onClick={toggleUserMenu} />
                  <div className={styles.userMenu}>
                    <div className={styles.links}>
                      <Link
                        to={getRoutePath("userSettings")}
                        className={styles.link}
                        onClick={toggleUserMenu}
                      >
                        <FontAwesomeIcon icon={["fas", "user"]} className={styles.icon} />
                        <span>User settings</span>
                      </Link>
                      <a
                        href="https://docs.meiro.io/books/meiro-events"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.link}
                        onClick={toggleUserMenu}
                      >
                        <FontAwesomeIcon icon={["fas", "life-ring"]} className={styles.icon} />
                        <span>Help</span>
                      </a>
                      <button onClick={logOut} className={styles.link}>
                        {isLoggingOut ? (
                          <Spinner size="xs" />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={["fas", "sign-out"]} className={styles.icon} />
                            <span>Log out</span>
                          </>
                        )}
                      </button>
                    </div>
                    <div className={styles.aboutWrapper}>
                      <div className={styles.about}>
                        <FontAwesomeIcon icon={["fas", "map-marker-alt"]} className={styles.icon} />
                        <span className={styles.text}>
                          Location: <strong>{MEIRO_LOCATION}</strong>
                        </span>
                      </div>
                      <div className={styles.about}>
                        <FontAwesomeIcon icon={["fas", "binary"]} className={styles.icon} />
                        <span className={styles.text}>
                          Version: <strong>{MEIRO_VERSION}</strong>
                        </span>
                      </div>
                      {buildDate && (
                        <div className={styles.about}>
                        <FontAwesomeIcon icon={["fas", "gear-code"]} className={styles.icon} />
                        <span className={styles.text}>
                          Build date: <strong>{format(new Date(`${buildDate}T00:00`), "d MMM yyyy")}</strong>
                        </span>
                      </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.secondaryBar}>
        <Switch>
          <Route path={getRoutePath("monitoring")}>
            <NavLink
              to={getRoutePath("monitoring")}
              exact
              className={styles.secondaryLink}
              activeClassName={styles.active}
            >
              monitoring
            </NavLink>
            <Route path={getRoutePath("monitoring.detail")}>
              <div>
                <FontAwesomeIcon icon={["fas", "chevron-right"]} />
              </div>
              <div className={classnames(styles.secondaryLink, styles.active)}>detail</div>
            </Route>
            <div>•</div>
            <NavLink
              to={getRoutePath("monitoring.alerts")}
              className={styles.secondaryLink}
              activeClassName={styles.active}
            >
              alerts
            </NavLink>
          </Route>
          <Route path={getRoutePath("users")}>
            <NavLink
              to={getRoutePath("users")}
              className={styles.secondaryLink}
              activeClassName={styles.active}
            >
              users
            </NavLink>
          </Route>
          <Route path={getRoutePath("userSettings")}>
            <NavLink
              to={getRoutePath("userSettings")}
              className={styles.secondaryLink}
              activeClassName={styles.active}
            >
              user settings
            </NavLink>
          </Route>
        </Switch>
      </div>
    </div>
  )
}
