import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCheck as faCheckSolid,
  faTimes as faTimesSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faCogs as faCogsSolid,
  faCaretDown as faCaretDownSolid,
  faChevronDown as faChevronDownSolid,
  faWrench as faWrenchSolid,
  faUser as faUserSolid,
  faLifeRing as faLifeRingSolid,
  faCloud as faCloudSolid,
  faCode as faCodeSolid,
  faChevronRight as faChevronRightSolid,
  faPlus as faPlusSolid,
  faTrashAlt as faTrashAltSolid,
  faUndoAlt as faUndoAltSolid,
  faPencilAlt as faPencilAltSolid,
  faInfoCircle as faInfoCircleSolid,
  faTrashRestoreAlt as faTrashRestoreAltSolid,
} from "@fortawesome/free-solid-svg-icons"
import {
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular,
} from "@fortawesome/free-regular-svg-icons"
import {
  faLockAlt as faLockAltSolid,
  faSignOut as faSignOutSolid,
  faTachometer as faTachometerSolid,
  faGearCode as faGearCodeSolid,
  faBinary as faBinarySolid,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faLockKeyhole,
} from "@fortawesome/pro-solid-svg-icons"

library.add(
  faCheckSolid,
  faEyeRegular,
  faEyeSlashRegular,
  faTimesSolid,
  faExclamationTriangleSolid,
  faCogsSolid,
  faChevronDownSolid,
  faCaretDownSolid,
  faTachometerSolid,
  faWrenchSolid,
  faUserSolid,
  faSignOutSolid,
  faLifeRingSolid,
  faCloudSolid,
  faCodeSolid,
  faChevronRightSolid,
  faPlusSolid,
  faTrashAltSolid,
  faUndoAltSolid,
  faPencilAltSolid,
  faInfoCircleSolid,
  faLockAltSolid,
  faTrashRestoreAltSolid,
  faGearCodeSolid,
  faBinarySolid,
  faMapMarkerAltSolid,
  faLockKeyhole,
)
