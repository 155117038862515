import React, { useCallback, useEffect, useRef } from "react"
import { formatDistanceToNowStrict } from "date-fns"
import { useLocation, useParams } from "react-router-dom"
import Card from "components/Card/Card"
import styles from "./SourceDetail.module.scss"
import EventTypeCard from "./components/EventTypeCard/EventTypeCard"
import { Source } from "types"
import { useDispatch } from "react-redux"
import alertActions from "store/alerts/alertActions"
import useTypedSelector from "hooks/useTypedSelector"
import { equals, prop } from "ramda"
import NotFound from "pages/NotFound/NotFound"
import Api from "services/api"
import { sources } from "consts"
import EventChart from "components/EventChart/EventChart"
import EventTable from "./components/EventTable/EventTable"
import EventAlertWrapper from "./components/EventAlertWrapper/EventAlertWrapper"
import Spinner from "components/Spinner/Spinner"
import useFetcher from "hooks/useFetcher"

export default function SourceDetail() {
  const { source } = useParams<{ source: Source }>()

  const dispatch = useDispatch()
  const sourceAlertIds = useTypedSelector(state => state.sourceAlertIds[source], equals)
  const events = useTypedSelector(state => state.events[source])
  const eventCounts = useTypedSelector(state => state.eventCounts[source])

  const isValidSource = sources.includes(source)
  const eventTypesFromEvents = isValidSource ? events.map(prop("eventType")) : []
  const eventTypesFromAlerts = isValidSource
    ? Object.keys(sourceAlertIds).filter(eventType => !eventTypesFromEvents.includes(eventType))
    : []
  const eventTypesUsed = eventTypesFromEvents.concat(eventTypesFromAlerts)
  const isEmpty = eventTypesUsed.length === 0

  const { search } = useLocation()
  const { hl_event_type, hl_alert_id } = Object.fromEntries(new URLSearchParams(search).entries())
  const refs = useRef<{ [eventType: string]: HTMLDivElement | null }>({})

  const scrollToHighligted = useCallback(() => {
    if (hl_event_type) {
      const cardToScrollTo = refs.current[hl_event_type]
      if (cardToScrollTo) {
        cardToScrollTo.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [hl_event_type])

  const { fetch: fetchAlerts, isLoading } = useFetcher(
    useCallback(() => Api.alerts.getAlerts(source), [source]),
  )

  useEffect(() => {
    if (!isValidSource) return

    fetchAlerts().then(alerts => {
      if (alerts) dispatch(alertActions.setAlerts({ alerts }))
      setTimeout(scrollToHighligted, 100)
    })
  }, [dispatch, fetchAlerts, isValidSource, scrollToHighligted])

  if (!isValidSource) {
    return <NotFound />
  }

  const lastTimestamp = new Date(
    Math.max(...events.map(({ lastTimestamp }) => lastTimestamp.getTime())),
  )

  return (
    <div className={styles.container}>
      <Card bgColor="light_blue_bg" className={styles.titleCard}>
        <div className={styles.title}>{source}</div>
        {events.length > 0 && (
          <div className={styles.lastTimestamp}>
            <div className={styles.label}>last event received</div>
            <div className={styles.value}>{formatDistanceToNowStrict(lastTimestamp)} ago</div>
          </div>
        )}
      </Card>
      {isLoading && <Spinner className={styles.spinner} />}
      {isEmpty && !isLoading && (
        <Card className={styles.emptyMessageCard}>No recent events for this source.</Card>
      )}
      {!isEmpty && !isLoading && (
        <>
          <Card className={styles.chartCard}>
            <EventChart eventCounts={eventCounts} />
          </Card>

          {eventTypesUsed.map(eventType => {
            const eventsForEventType = events.find(
              eventObject => eventObject.eventType === eventType,
            )
            return (
              <EventTypeCard
                key={eventType}
                eventType={eventType}
                ref={element => {
                  refs.current[eventType] = element
                }}
                expand={eventType === hl_event_type}
              >
                <EventTable events={eventsForEventType?.events ?? []} />
                <EventAlertWrapper
                  source={source}
                  eventType={eventType}
                  alertIdToHighlight={hl_alert_id}
                />
              </EventTypeCard>
            )
          })}
        </>
      )}
    </div>
  )
}
