import { assoc, dissoc } from "ramda"
import { Alert } from "types"
import { AlertsAction, ALERT_ACTION_TYPES } from "./alertActions"

type AlertEditsState = {
  [id: string]: Alert
}

const initAlertEdits = {}

export default function alertEdits(
  state: AlertEditsState = initAlertEdits,
  action: AlertsAction,
): AlertEditsState {
  switch (action.type) {
    case ALERT_ACTION_TYPES.ADD_NEW_ALERT: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.SAVE_CREATED_ALERT: {
      return dissoc(action.payload.tempId, state)
    }
    case ALERT_ACTION_TYPES.SAVE_MODIFIED_ALERT: {
      return dissoc(action.payload.id, state)
    }
    case ALERT_ACTION_TYPES.UNDO_CHANGES: {
      return dissoc(action.payload.id, state)
    }
    case ALERT_ACTION_TYPES.EDIT_ALERT: {
      return assoc(action.payload.id, action.payload, state)
    }
    case ALERT_ACTION_TYPES.DELETE_ALERT: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.SET_ALERTS: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.WIPE_ALERTS: {
      return initAlertEdits
    }
    default:
      return state
  }
}
