import { SourceEventCountsAPI, SourceEventsAPI } from "types"

export const eventsFromApiFormat = ({ source, event_types }: SourceEventsAPI) => ({
  source,
  eventTypes: event_types.map(({ event_type, last_timestamp, events }) => ({
    eventType: event_type,
    lastTimestamp: new Date(last_timestamp),
    events: events.map(event => ({
      ...event,
      timestamp: new Date(event.timestamp),
    })),
  })),
})

export const eventCountsFromApiFormat = ({ source, event_types }: SourceEventCountsAPI) => ({
  source,
  counts: event_types.map(({ event_type, count_per_minute }) => ({
    eventType: event_type,
    countPerMinute: count_per_minute,
  })),
})
