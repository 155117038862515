import axiosStatic, { AxiosError } from "axios"
import AuthService from "./auth"
import {
  alertFromApiFormat,
  alertToCreateReqPayload,
  alertToModifyReqPayload,
} from "./utils/alertMappers"
import { Alert, AlertAPI, AuthOptions, Source, SystemInfo, User, UsersAPI } from "types"
import { API_URL, NOTIFS } from "consts"
import toast from "./toast"
import history from "./history"
import { getRoutePath } from "./routes"

const axios = axiosStatic.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 10000,
})

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

const publicRoutes = ["/auth_options", "/users/login", "/users/okta_login", "/users/password"]

axios.interceptors.request.use(async config => {
  if (config.url && publicRoutes.includes(config.url)) return config

  const userAuth = AuthService.getCurrentUser()

  if (!userAuth || userAuth.isExpired()) {
    await AuthService.logout()
    history.push(getRoutePath("login"))

    throw new axiosStatic.Cancel()
  }

  config.headers["x-access-token"] = userAuth.accessToken
  return config
})

async function errorHandler(error: AxiosError) {
  const { response, config } = error

  if (!response) {
    if (!axiosStatic.isCancel(error)) {
      toast.error(NOTIFS.ERROR.NETWORK_ERROR)
      console.error(error)
    }
    return null
  }

  const { status, data } = response

  if (status >= 500) {
    toast.error(NOTIFS.ERROR.SERVER_ERROR)
    console.error(JSON.stringify(data))
    return null
  }

  if (status === 401) {
    if (config.url === "/users/login" && config.method === "post") {
      toast.error(NOTIFS.ERROR.INVALID_LOGIN)
    } else {
      await AuthService.logout()
      history.push(getRoutePath("login"))
    }
    return null
  }

  toast.error(`Error: ${data.message}`)
  console.error(JSON.stringify(data))
  return null
}

// return values from the axios methods are retyped in apiUtils/axios.d.ts to match interceptors
axios.interceptors.response.use(res => res.data, errorHandler)

type UserProps = { email: string }

const Api = {
  userAuth: {
    authOptions: () => axios.get<AuthOptions>("/auth_options"),
    login: (email: string, password: string) =>
      axios.post<{ token: string }>("/users/login", { email, password }),
    oktaLogin: (code: string) => axios.post<{ token: string }>("/users/okta_login", { code }),
    logout: () => axios.delete<{}>("/users/login"),
    refreshToken: (token: string) => axios.patch<{ token: string }>("/users/login", { token }),
    resetPassword: (email: string, password: string, token: string) =>
      axios.patch<{}>("/users/password", { email, password, token }),
    changePassword: (id: string, old_password: string, new_password: string) =>
      axios.post<User>(`/users/${id}/change_password`, { old_password, new_password }),
    sendResetPasswordEmail: (email: string) => axios.post<{}>("/users/password", { email }),
  },
  users: {
    getUserById: (id: string) => axios.get<User>(`/users/${id}`),
    getUsers: () => axios.get<UsersAPI>("/users"),
    getDeletedUsers: () => axios.get<UsersAPI>("/users/trash"),
    createUser: (email: string, password: string) =>
      axios.post<User>("/users", { email, password }),
    inviteUser: (email: string) => axios.post<User>("/users/invite", { email }),
    modifyUser: (id: string, changes: Partial<UserProps>) =>
      axios.patch<User>(`/users/${id}`, changes),
    deleteUser: (user: User) => axios.delete<User>(`/users/${user.id}`),
    restoreUser: (user: User) => axios.patch<User>(`/users/trash/${user.id}`),
  },
  alerts: {
    async getAlerts(source?: Source) {
      const alerts = await axios.get<AlertAPI[]>("/alerts", { params: { source } })
      return alerts?.map(alertFromApiFormat)
    },
    async createAlert(alert: Alert) {
      const payload = alertToCreateReqPayload(alert)
      const createdAlert = await axios.post<AlertAPI>("/alerts", payload)
      return createdAlert && alertFromApiFormat(createdAlert)
    },
    async modifyAlert(alert: Alert) {
      const payload = alertToModifyReqPayload(alert)
      const modifiedAlert = await axios.patch<AlertAPI>(`/alerts/${alert.id}`, payload)
      return modifiedAlert && alertFromApiFormat(modifiedAlert)
    },
    async deleteAlert(alert: Alert) {
      return axios.delete<{}>(`/alerts/${alert.id}`)
    },
  },
  systemInfo: {
    async getSystemInfo() {
      const response = await axios.get<SystemInfo>("/system_info")
      return response?.system_info
    }
  }
}

export default Api
