import { AccessToken } from "types"

const USER_KEY = "user"
const EXPIRATION_BUFFER = 10000

type UserAuth = AccessToken & { accessToken: string }

function isExpired(this: UserAuth) {
  return this.exp * 1000 - Date.now() < EXPIRATION_BUFFER
}

function get() {
  try {
    const valueFromStorage = window.localStorage.getItem(USER_KEY)
    return valueFromStorage ? { ...(JSON.parse(valueFromStorage) as UserAuth), isExpired } : null
  } catch {
    return null
  }
}

function set(userAuth: UserAuth) {
  window.localStorage.setItem(USER_KEY, JSON.stringify(userAuth))
}

function remove() {
  window.localStorage.removeItem(USER_KEY)
}

export const UserStorage = {
  get,
  set,
  remove,
}
