import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/Button/Button"
import Card from "components/Card/Card"
import DialogModal from "components/DialogModal/DialogModal"
import Spinner from "components/Spinner/Spinner"
import { NOTIFS } from "consts"
import { isTempId } from "helpers/tempId"
import useFetcher from "hooks/useFetcher"
import useTypedSelector from "hooks/useTypedSelector"
import { prop, sortBy } from "ramda"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import Api from "services/api"
import { getRoutePath } from "services/routes"
import toast from "services/toast"
import alertActions from "store/alerts/alertActions"
import { Alert } from "types"
import styles from "./Alerts.module.scss"

const alertConditions = (alert: Alert) => (
  <>
    <div>
      Activity does not occur for <span>{alert.inactivity.duration}</span>{" "}
      <span>{alert.inactivity.unit}</span>
      {alert.filterRules.length > 0 && " in which"}
    </div>
    {alert.filterRules.map(rule => (
      <div key={JSON.stringify(rule)}>
        <span>{rule.path}</span> {rule.operator === "CONTAINS" ? "contains" : "doesn't contain"}{" "}
        <span>{rule.value}</span>
      </div>
    ))}
  </>
)

const alertEmails = (emails: string[]) => (
  <>
    {emails.map(email => (
      <div key={email}>{email}</div>
    ))}
  </>
)

export default function Alerts() {
  const dispatch = useDispatch()
  const alerts = useTypedSelector(state => state.alerts)
  const filteredAlerts = Object.values(alerts).filter(alert => !isTempId(alert.id))
  const sortedAlerts = sortBy(prop("source"), sortBy(prop("eventType"), filteredAlerts))
  const [alertToDelete, setAlertToDelete] = useState(sortedAlerts[0])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const isEmpty = sortedAlerts.length === 0

  const { fetch: fetchAlerts, isLoading } = useFetcher(Api.alerts.getAlerts)

  useEffect(() => {
    fetchAlerts().then(alerts => alerts && dispatch(alertActions.setAlerts({ alerts })))
  }, [dispatch, fetchAlerts])

  const openDeleteModal = useCallback(alert => {
    setAlertToDelete(alert)
    setShowDeleteModal(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleting(true)
    const response = await Api.alerts.deleteAlert(alertToDelete)
    if (response) {
      dispatch(alertActions.deleteAlert(alertToDelete))
      toast.success(NOTIFS.SUCCESS.ALERT_DELETED)
    }
    setIsDeleting(false)
    setShowDeleteModal(false)
  }, [alertToDelete, dispatch])

  const closeDeleteModal = useCallback(() => setShowDeleteModal(false), [])

  return (
    <>
      {showDeleteModal && (
        <DialogModal
          title="delete alert"
          message={`Do you really want to delete alert for ${alertToDelete.eventType}?`}
          type="delete"
          isLoading={isDeleting}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
      )}
      <Card className={styles.titleCard} bgColor="light_blue_bg">
        Alerts
      </Card>
      <Card className={styles.mainCard}>
        {isLoading && <Spinner className={styles.spinner} />}
        {!isLoading && isEmpty && (
          <div className={styles.emptyMessage}>
            There are no alerts. You can create alerts by selecting a source on the{" "}
            <Link to={getRoutePath("monitoring")}>dashboard</Link> and clicking on the Detail
            button.
          </div>
        )}
        {!isLoading && !isEmpty && (
          <table className={styles.alertsTable}>
            <thead>
              <tr>
                <th>event type</th>
                <th>alert conditions</th>
                <th>e-mails</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedAlerts.map(alert => (
                <tr key={alert.id} data-testid="alert-row">
                  <td>
                    <div className={styles.source}>
                      {alert.source}{" "}
                      <FontAwesomeIcon icon={["fas", "chevron-right"]} className={styles.icon} />
                    </div>
                    <div className={styles.eventType}>{alert.eventType}</div>
                  </td>
                  <td className={styles.alertConditions}>{alertConditions(alert)}</td>
                  <td className={styles.emails}>{alertEmails(alert.emails)}</td>
                  <td>
                    <div className={styles.buttons}>
                      <Link
                        to={{
                          pathname: getRoutePath("monitoring.detail", { source: alert.source }),
                          search:
                            "?" +
                            new URLSearchParams({
                              hl_event_type: alert.eventType,
                              hl_alert_id: alert.id,
                            }).toString(),
                        }}
                        tabIndex={-1}
                      >
                        <Button
                          kind="text-only"
                          faded
                          color="text_main"
                          icon={["fas", "pencil-alt"]}
                          className={styles.rowButton}
                          size="xl"
                          data-tooltip="Edit"
                          data-testid="edit-button"
                        />
                      </Link>
                      <Button
                        kind="text-only"
                        faded
                        color="red"
                        icon={["fas", "trash-alt"]}
                        onClick={() => openDeleteModal(alert)}
                        className={styles.rowButton}
                        size="xl"
                        data-tooltip="Delete"
                        data-testid="delete-button"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Card>
    </>
  )
}
