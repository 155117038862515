import { assoc, dissoc } from "ramda"
import { Alert } from "types"
import { AlertsAction, ALERT_ACTION_TYPES } from "./alertActions"

type AlertsState = {
  [id: string]: Alert
}

const initAlerts = {}

export default function alerts(state: AlertsState = initAlerts, action: AlertsAction): AlertsState {
  switch (action.type) {
    case ALERT_ACTION_TYPES.ADD_NEW_ALERT: {
      return assoc(action.payload.id, action.payload, state)
    }
    case ALERT_ACTION_TYPES.SAVE_CREATED_ALERT: {
      return assoc(action.payload.alert.id, action.payload.alert, state)
    }
    case ALERT_ACTION_TYPES.SAVE_MODIFIED_ALERT: {
      return assoc(action.payload.id, action.payload, state)
    }
    case ALERT_ACTION_TYPES.UNDO_CHANGES: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.EDIT_ALERT: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.DELETE_ALERT: {
      return dissoc(action.payload.id, state)
    }
    case ALERT_ACTION_TYPES.SET_ALERTS: {
      const { alerts } = action.payload
      return { ...state, ...Object.fromEntries(alerts.map(alert => [alert.id, alert])) }
    }
    case ALERT_ACTION_TYPES.WIPE_ALERTS: {
      return initAlerts
    }
    default:
      return state
  }
}
