import React, { useCallback, useRef } from "react"
import styles from "./PayloadModal.module.scss"
import Modal from "components/Modal/Modal"
import Button from "components/Button/Button"
import toast from "services/toast"
import { NOTIFS } from "consts"

type PayloadModalProps = {
  payload: string
  onClose: () => void
}

export default function PayloadModal({ payload, onClose }: PayloadModalProps) {
  const copyToClipboard = useCallback(() => {
    if (payloadRef.current) {
      window.getSelection()?.selectAllChildren(payloadRef.current)
      document.execCommand("copy")
      window.getSelection()?.removeAllRanges()
      toast.success(NOTIFS.SUCCESS.COPIED_TO_CB)
    }
  }, [])
  const payloadRef = useRef<HTMLDivElement>(null)

  return (
    <Modal className={styles.payloadModal} onClose={onClose} data-testid="payload-modal">
      <div className={styles.header}>
        <div className={styles.title}>payload preview</div>
        <Button kind="text-only" onClick={copyToClipboard}>
          copy to clipboard
        </Button>
        <Button
          icon={["fas", "times"]}
          kind="text-only"
          onClick={onClose}
          color="grey_7"
          className={styles.closeIcon}
          size="xl"
        />
      </div>
      <div ref={payloadRef} className={styles.payload}>
        {JSON.stringify(payload, null, 4)}
      </div>
    </Modal>
  )
}
