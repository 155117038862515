import { Alert } from "types"

type AlertId = Alert["id"]

export const ADD_NEW_ALERT = "ADD_NEW_ALERT" as const
export const SAVE_CREATED_ALERT = "SAVE_CREATED_ALERT" as const
export const SAVE_MODIFIED_ALERT = "SAVE_MODIFIED_ALERT" as const
export const UNDO_CHANGES = "UNDO_CHANGES" as const
export const EDIT_ALERT = "EDIT_ALERT" as const
export const DELETE_ALERT = "DELETE_ALERT" as const
export const SET_ALERTS = "SET_ALERTS" as const
export const WIPE_ALERTS = "WIPE_ALERTS" as const

export const ALERT_ACTION_TYPES = {
  ADD_NEW_ALERT: "ADD_NEW_ALERT",
  SAVE_CREATED_ALERT: "SAVE_CREATED_ALERT",
  SAVE_MODIFIED_ALERT: "SAVE_MODIFIED_ALERT",
  UNDO_CHANGES: "UNDO_CHANGES",
  EDIT_ALERT: "EDIT_ALERT",
  DELETE_ALERT: "DELETE_ALERT",
  SET_ALERTS: "SET_ALERTS",
  WIPE_ALERTS: "WIPE_ALERTS",
} as const

const alertActions = {
  addNewAlert: (payload: Alert) => ({ type: ADD_NEW_ALERT, payload }),
  saveCreatedAlert: (payload: { tempId: AlertId; alert: Alert }) => ({
    type: SAVE_CREATED_ALERT,
    payload,
  }),
  saveModifiedAlert: (payload: Alert) => ({ type: SAVE_MODIFIED_ALERT, payload }),
  undoChanges: (payload: { id: AlertId }) => ({ type: UNDO_CHANGES, payload }),
  editAlert: (payload: Alert) => ({ type: EDIT_ALERT, payload }),
  deleteAlert: (payload: Alert) => ({ type: DELETE_ALERT, payload }),
  setAlerts: (payload: { alerts: Alert[] }) => ({ type: SET_ALERTS, payload }),
  wipeAlerts: () => ({ type: WIPE_ALERTS }),
}

export type AlertsAction = ReturnType<typeof alertActions[keyof typeof alertActions]>

export default alertActions
