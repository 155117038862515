import React, { ComponentProps } from "react"
import classnames from "classnames"
import styles from "./Spinner.module.scss"

type SpinnerSize = "xs" | "sm" | "md" | "lg"
type SpinnerProps = { size?: SpinnerSize } & ComponentProps<"svg">

export default function Spinner({ className, size = "md" }: SpinnerProps) {
  return (
    <div
      className={classnames(
        styles.wrapper,
        styles[("size_" + size) as keyof typeof styles],
        className,
      )}
    >
      <svg
        className={styles.spinner}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
      >
        <path fill="none" strokeWidth="10" stroke="currentColor" d="M 50 5 A 45 45 0 0 1 95 50" />
        <path
          fill="none"
          strokeWidth="10"
          stroke="currentColor"
          strokeOpacity="0.25"
          d="M 50 5 A 45 45 0 1 0 95 50"
        />
      </svg>
    </div>
  )
}
