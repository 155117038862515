import { sources } from "consts"
import { EventTypeEvents, Source, SourceEvents } from "types"

const SET_EVENTS = "SET_EVENTS" as const

export const setEvents = (payload: SourceEvents) => ({ type: SET_EVENTS, payload })

type EventsAction = ReturnType<typeof setEvents>
export type EventsState = {
  [K in Source]: EventTypeEvents[]
}

const initEvents = Object.fromEntries(
  sources.map<[Source, EventTypeEvents[]]>(sourceSlug => [sourceSlug, []]),
) as EventsState

export default function events(state: EventsState = initEvents, action: EventsAction) {
  switch (action.type) {
    case SET_EVENTS: {
      return {
        ...state,
        [action.payload.source]: action.payload.eventTypes,
      }
    }
    default:
      return state
  }
}
