import { NOTIFS, WS_URL } from "consts"
import { equals } from "ramda"
import { io } from "socket.io-client"
import { setEventCounts } from "store/events/eventCountsReducer"
import { setEvents } from "store/events/eventsReducer"
import store from "store/store"
import { SourceEventCountsAPI, SourceEventsAPI } from "types"
import toast from "./toast"
import { eventCountsFromApiFormat, eventsFromApiFormat } from "./utils/eventMappers"

let showedNetworkError = false

const socket = io(WS_URL + "/events", {
  path: "/stats/socket.io",
  autoConnect: false,
  transports: ["websocket"],
})

function connect() {
  socket.offAny()
  socket.on("statistics", saveEvents)
  socket.on("datasource", saveEventCounts)
  socket.on("connect_error", handleConnectError)
  showedNetworkError = false
  socket.connect()
}

function disconnect() {
  socket.offAny()
  socket.disconnect()
}

function saveEvents(payload: SourceEventsAPI) {
  const reformattedPayload = eventsFromApiFormat(payload)
  const { source, eventTypes: incomingEvents } = reformattedPayload
  const currentEvents = store.getState().events[source]
  if (!equals(incomingEvents, currentEvents)) {
    store.dispatch(setEvents(reformattedPayload))
  }
}

function saveEventCounts(payload: SourceEventCountsAPI) {
  const reformattedPayload = eventCountsFromApiFormat(payload)
  const { source, counts: incomingEventCounts } = reformattedPayload
  const currentEventCounts = store.getState().eventCounts[source]
  if (!equals(incomingEventCounts, currentEventCounts)) {
    store.dispatch(setEventCounts(reformattedPayload))
  }
}

async function handleConnectError(error: Error) {
  if (!showedNetworkError) {
    toast.error(NOTIFS.ERROR.NETWORK_ERROR)
    console.error(error)
    showedNetworkError = true
  }
}

const SocketService = {
  connect,
  disconnect,
  isConnected: socket.connected,
}

export default SocketService
