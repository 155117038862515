import { omit, uniq } from "ramda"
import { Alert, AlertAPI, TimeUnit } from "types"

function timeFromApiFormat(seconds: number): { duration: number; unit: TimeUnit } {
  if (seconds % (24 * 60 * 60) === 0) return { duration: seconds / (24 * 60 * 60), unit: "days" }
  if (seconds % (60 * 60) === 0) return { duration: seconds / (60 * 60), unit: "hrs" }
  return { duration: Math.round(seconds / 60), unit: "mins" }
}

function timeToApiFormat(duration: number, unit: TimeUnit) {
  if (unit === "days") return duration * 24 * 60 * 60
  if (unit === "hrs") return duration * 60 * 60
  return duration * 60
}

const pathFromApiFormat = (path: string) => path.slice(1, -1)
const pathToApiFormat = (path: string) => "{" + path + "}"

export const alertToApiFormat = ({
  eventType,
  emails,
  filterRules,
  inactivity: { duration, unit },
  ...alert
}: Alert): AlertAPI => ({
  event_type: eventType,
  emails: uniq(emails),
  filters: filterRules.map(({ path, ...rule }) => ({
    json_path: pathToApiFormat(path),
    ...rule,
  })),
  inactivity_time: timeToApiFormat(duration, unit),
  ...alert,
})

export const alertFromApiFormat = ({
  event_type,
  filters,
  inactivity_time,
  ...alert
}: AlertAPI): Alert => ({
  eventType: event_type,
  filterRules: filters
    ? filters.map(({ json_path, ...rule }) => ({ path: pathFromApiFormat(json_path), ...rule }))
    : [],
  inactivity: timeFromApiFormat(inactivity_time),
  ...alert,
})

export const alertToCreateReqPayload = (alert: Alert) => omit(["id"], alertToApiFormat(alert))
export const alertToModifyReqPayload = (alert: Alert) =>
  omit(["id", "source", "event_type"], alertToApiFormat(alert))
