import { sources } from "consts"
import { EventTypeCounts, Source, SourceEventCounts } from "types"

const SET_EVENT_COUNTS = "SET_EVENT_COUNTS" as const

export const setEventCounts = (payload: SourceEventCounts) => ({
  type: SET_EVENT_COUNTS,
  payload,
})

type EventCountsAction = ReturnType<typeof setEventCounts>
export type EventCountsState = {
  [K in Source]: EventTypeCounts[]
}

const initEventCounts = Object.fromEntries(
  sources.map<[Source, EventTypeCounts[]]>(sourceSlug => [sourceSlug, []]),
) as EventCountsState

export default function eventCounts(
  state: EventCountsState = initEventCounts,
  action: EventCountsAction,
) {
  switch (action.type) {
    case SET_EVENT_COUNTS: {
      return {
        ...state,
        [action.payload.source]: action.payload.counts,
      }
    }
    default:
      return state
  }
}
