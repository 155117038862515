import { useCallback, useState } from "react"

export default function useFetcher<T>(fetcher: () => Promise<T>) {
  const [data, setData] = useState<T | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await fetcher()
      setData(response)
      return response
    } catch (error) {
      setError(error)
      return null
    } finally {
      setIsLoading(false)
    }
  }, [fetcher])

  return { fetch, isLoading, error, data }
}
