import React, { ComponentProps, ReactNode } from "react"
import classnames from "classnames"
import styles from "./DialogModal.module.scss"
import Modal from "components/Modal/Modal"
import Button from "components/Button/Button"

type DialogModalProps = {
  title?: string
  message: ReactNode
  type: "delete"
  isLoading?: boolean
  onConfirm: () => void
  onClose: () => void
} & ComponentProps<"div">

export default function DialogModal({
  className,
  title,
  message,
  type,
  isLoading = false,
  onConfirm,
  onClose,
}: DialogModalProps) {
  return (
    <Modal
      className={classnames(styles.container, className)}
      onClose={onClose}
      data-testid="dialog-modal"
    >
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <div className={styles.expand}>
          <Button kind="text-only" onClick={onClose} color="grey_7">
            cancel
          </Button>
        </div>
        <Button color="red" onClick={onConfirm} isLoading={isLoading}>
          delete
        </Button>
      </div>
    </Modal>
  )
}
