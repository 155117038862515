import Button from "components/Button/Button"
import Card from "components/Card/Card"
import DialogModal from "components/DialogModal/DialogModal"
import Spinner from "components/Spinner/Spinner"
import { NOTIFS } from "consts"
import { assocPath, prop, sortBy, without } from "ramda"
import React, { useCallback, useEffect, useState } from "react"
import Api from "services/api"
import toast from "services/toast"
import { User } from "types"
import styles from "./Users.module.scss"
import CreateUserModal, { FormData } from "./components/CreateUserModal/CreateUserModal"
import EditableValue from "components/EditableValue/EditableValue"
import { isValidEmail } from "helpers/validation"
import classnames from "classnames"
import useFetcher from "hooks/useFetcher"
import AuthService from "services/auth"

export default function Users() {
  const [users, setUsers] = useState<User[]>([])
  const sortedUsers = sortBy(prop("email"), users)
  const [userToDelete, setUserToDelete] = useState(users[0])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [query, setQuery] = useState("")
  const isEmpty = users.length === 0 // This should never happen
  const currentUser = AuthService.getCurrentUser()

  const { fetch: fetchUsers, isLoading } = useFetcher(Api.users.getUsers)

  useEffect(() => {
    fetchUsers().then(res => res && setUsers(res.users))
  }, [fetchUsers])

  const openDeleteModal = useCallback(user => {
    setUserToDelete(user)
    setShowDeleteModal(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    setIsDeleting(true)
    const response = await Api.users.deleteUser(userToDelete!)
    if (response) {
      toast.success(NOTIFS.SUCCESS.USER_DELETED)
      setUsers(without([userToDelete!]))
    }
    setIsDeleting(false)
    setShowDeleteModal(false)
  }, [userToDelete])

  const closeDeleteModal = useCallback(() => setShowDeleteModal(false), [])

  const openCreateModal = useCallback(() => setShowCreateModal(true), [])
  const closeCreateModal = useCallback(() => setShowCreateModal(false), [])

  const submitCreatedUsers = useCallback(
    async (data: FormData) => {
      const promises = data.invite
        ? data.users.map(({ email }) => Api.users.inviteUser(email))
        : data.users.map(({ email, password }) => Api.users.createUser(email, password))
      const response = await Promise.all(promises)
      if (response.some(Boolean)) {
        toast.success(data.invite ? NOTIFS.SUCCESS.USER_INVITED : NOTIFS.SUCCESS.USER_CREATED)
        fetchUsers().then(res => res && setUsers(res.users))
        setShowCreateModal(false)
      }
    },
    [fetchUsers],
  )

  const changeUserEmail = useCallback(
    async (id, email) => {
      const response = await Api.users.modifyUser(id, { email })
      if (response) {
        toast.success(NOTIFS.SUCCESS.USER_MODIFIED)
        const index = users.findIndex(user => user.id === id)
        setUsers(assocPath([index, "email"], email))
      }
    },
    [users],
  )

  const doSearch = useCallback(e => setQuery(e.target.value), [])
  const clearSearch = useCallback(() => setQuery(""), [])
  const filteredUsers =
    query === "" ? sortedUsers : sortedUsers.filter(({ email }) => email.includes(query))

  return (
    <>
      {showDeleteModal && (
        <DialogModal
          title="delete user"
          message={`Do you really want to delete user ${userToDelete!.email}?`}
          type="delete"
          isLoading={isDeleting}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
      )}
      {showCreateModal && (
        <CreateUserModal onClose={closeCreateModal} onSubmit={submitCreatedUsers} />
      )}
      <Card className={styles.titleCard} bgColor="light_blue_bg">
        <div className={styles.title}>Users</div>
        <div className={styles.search}>
          <input
            className={styles.searchInput}
            value={query}
            onChange={doSearch}
            placeholder="Type to search"
          />
          {query && (
            <Button
              icon={["fas", "times"]}
              className={styles.clearButton}
              size="xl"
              color="grey_9"
              onClick={clearSearch}
              kind="text-only"
            />
          )}
        </div>
        <Button icon={["fas", "plus"]} size="lg" onClick={openCreateModal}>
          create user
        </Button>
      </Card>
      <Card className={styles.mainCard}>
        {isLoading && <Spinner className={styles.spinner} />}
        {!isLoading && isEmpty && <div className={styles.emptyMessage}>There are no users.</div>}
        {!isLoading && !isEmpty && (
          <table className={styles.usersTable}>
            <thead>
              <tr>
                <th>e-mail</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user.id} data-testid="user-row">
                  <td>
                    <EditableValue
                      initValue={user.email}
                      onChange={email => changeUserEmail(user.id, email)}
                      validate={isValidEmail}
                    />
                  </td>
                  <td>
                    <div className={styles.buttons}>
                      <Button
                        kind="text-only"
                        faded
                        color="red"
                        icon={["fas", "trash-alt"]}
                        onClick={() => openDeleteModal(user)}
                        className={classnames(styles.rowButton, {
                          [styles.hidden]: user.id === currentUser?.user_id,
                        })}
                        size="xl"
                        data-tooltip="Delete"
                        data-testid="delete-button"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Card>
    </>
  )
}
