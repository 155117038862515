import React, { useState, useCallback, useEffect } from "react"
import classnames from "classnames"
import styles from "./EventAlert.module.scss"
import { Alert } from "types"
import { isTempId } from "helpers/tempId"
import { append, assocPath, lensProp, over, remove } from "ramda"
import Button from "components/Button/Button"
import { Controller, useForm } from "react-hook-form"
import { isValidEmail, isValidRegex } from "helpers/validation"
import Chip from "components/Chip/Chip"
import TextInput from "components/TextInput/TextInput"

type EventAlertProps = {
  alert: Alert | null
  switcherData: { index: number; alerts: { isUnsaved: boolean }[] }
  onSwitch: (i: number) => void
  onAddNewAlert: () => void
  onCancelNewAlert: () => void
  onDeleteAlert: () => void
  onSaveAlert: () => Promise<void>
  onUndoChanges: () => void
  onEditAlert: (alert: Alert) => void
  highlightAlert?: boolean
}

export default function EventAlert({
  alert,
  switcherData,
  onSwitch,
  onAddNewAlert,
  onCancelNewAlert,
  onDeleteAlert,
  onSaveAlert,
  onUndoChanges,
  onEditAlert,
  highlightAlert = false,
}: EventAlertProps) {
  const [isSaving, setIsSaving] = useState(false)
  const [email, setEmail] = useState("")
  const { register, setValue, handleSubmit, control, errors, clearErrors } = useForm({
    mode: "onTouched",
  })
  const isNewAlert = alert && isTempId(alert.id)
  const isAlertUnsaved = switcherData.alerts[switcherData.index]?.isUnsaved

  useEffect(() => {
    if (alert) {
      register("emails", { min: 1 })
      setValue("emails", alert.emails.length)
    }
  }, [alert, register, setValue])

  const editAlert = useCallback((fn: (alert: Alert) => Alert) => onEditAlert(fn(alert!)), [
    alert,
    onEditAlert,
  ])

  const saveAlert = useCallback(async () => {
    setIsSaving(true)
    await onSaveAlert()
    setIsSaving(false)
  }, [onSaveAlert])

  const undoChanges = useCallback(() => {
    clearErrors()
    onUndoChanges()
  }, [clearErrors, onUndoChanges])

  const addEmail = useCallback(() => {
    editAlert(over(lensProp("emails"), append(email)))
    setEmail("")
  }, [editAlert, email])

  const handleEmailKeyPress = useCallback(
    e => {
      if (e.key === "Enter" && isValidEmail(email) === true) addEmail()
    },
    [addEmail, email],
  )

  const removeEmail = useCallback(i => editAlert(over(lensProp("emails"), remove(i, 1))), [
    editAlert,
  ])

  const editInactivityDuration = useCallback(
    e => editAlert(assocPath(["inactivity", "duration"], e.target.value)),
    [editAlert],
  )

  const editInactivityUnit = useCallback(
    e => editAlert(assocPath(["inactivity", "unit"], e.target.value)),
    [editAlert],
  )

  const editFilterPath = useCallback(
    (i, e) => editAlert(assocPath(["filterRules", i, "path"], e.target.value)),
    [editAlert],
  )

  const editFilterOperator = useCallback(
    (i, e) => editAlert(assocPath(["filterRules", i, "operator"], e.target.value)),
    [editAlert],
  )

  const editFilterValue = useCallback(
    (i, e) => editAlert(assocPath(["filterRules", i, "value"], e.target.value)),
    [editAlert],
  )

  const removeFilterRule = useCallback(
    i => editAlert(over(lensProp("filterRules"), remove(i, 1))),
    [editAlert],
  )

  const makeNewFilterRule = () => ({ path: "", operator: "CONTAINS", value: "" })
  const addFilterRule = useCallback(
    () => editAlert(over(lensProp("filterRules"), append(makeNewFilterRule()))),
    [editAlert],
  )

  return (
    <div
      className={classnames(styles.container, { [styles.highlighted]: highlightAlert })}
      data-testid="event-alert"
    >
      <div className={styles.buttonsBar}>
        <div className={styles.flexWrapper}>
          <div className={styles.alertButtons}>
            {alert && (
              <div className={styles.alertSwitch}>
                {switcherData.alerts.map(({ isUnsaved }, i) => (
                  <div
                    className={classnames(styles.alertButtonWrapper, {
                      [styles.isSelected]: i === switcherData.index,
                      [styles.isUnsaved]: isUnsaved,
                    })}
                    key={i}
                  >
                    <Button
                      onClick={() => onSwitch(i)}
                      color={switcherData.index === i ? "me_primary" : "white"}
                      kind="text-only"
                    >
                      alert {i + 1}
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <Button
              onClick={onAddNewAlert}
              icon={["fas", "plus"]}
              color="me_primary"
              kind="text-only"
            >
              add alert
            </Button>
          </div>
          {alert && (
            <div className={styles.mainButtons}>
              {isNewAlert ? (
                <Button
                  onClick={onCancelNewAlert}
                  className={styles.mainButton}
                  color="grey_7"
                  kind="secondary"
                  icon={["fas", "times"]}
                  disabled={isSaving}
                >
                  cancel
                </Button>
              ) : (
                <>
                  <Button
                    onClick={onDeleteAlert}
                    className={styles.mainButton}
                    color="red"
                    kind="secondary"
                    icon={["fas", "trash-alt"]}
                    disabled={isSaving}
                  >
                    delete
                  </Button>
                  <Button
                    onClick={undoChanges}
                    className={styles.mainButton}
                    color="grey_7"
                    kind="secondary"
                    icon={["fas", "undo-alt"]}
                    disabled={isSaving || !isAlertUnsaved}
                  >
                    undo changes
                  </Button>
                </>
              )}
              <Button
                onClick={handleSubmit(saveAlert)}
                className={styles.mainButton}
                icon={["fas", "check"]}
                isLoading={isSaving}
                disabled={!isAlertUnsaved}
              >
                save
              </Button>
            </div>
          )}
        </div>
      </div>
      {alert && (
        <div className={styles.alertDetail}>
          <div className={styles.emailSettings}>
            <div className={styles.title}>E-mails</div>
            <div className={styles.emailsMain}>
              <div className={styles.emailInputWrapper}>
                <TextInput
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  onKeyPress={handleEmailKeyPress}
                  placeholder="type & press Enter to add"
                  className={styles.emailInput}
                  data-testid="email-input"
                />
                <Button
                  onClick={addEmail}
                  color="grey_7"
                  kind="secondary"
                  icon={["fas", "plus"]}
                  disabled={isValidEmail(email) !== true}
                />
              </div>
              <div className={styles.emailChips} data-testid="email-chips">
                {alert.emails.length === 0 && (
                  <>
                    {errors.emails ? (
                      <span className={styles.errorEmailsMessage}>You must add an e-mail.</span>
                    ) : (
                      <span className={styles.emptyEmailsMessage}>No e-mails added yet.</span>
                    )}
                  </>
                )}
                {alert.emails.map((email, i) => (
                  <Chip onDelete={() => removeEmail(i)} key={i}>
                    {email}
                  </Chip>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.filterSettings}>
            <div className={styles.title}>Alerts</div>
            <div className={styles.filtersMain}>
              <div className={styles.topRow}>
                <span>
                  <strong>Activity</strong> does not occur for
                </span>
                <Controller
                  control={control}
                  defaultValue={alert.inactivity.duration}
                  name="inactivity.duration"
                  rules={{ required: true }}
                  render={({ ref, onChange, onBlur }) => (
                    <>
                      <TextInput
                        type="number"
                        ref={ref}
                        onChange={e => {
                          editInactivityDuration(e)
                          onChange(e)
                        }}
                        value={alert.inactivity.duration}
                        className={styles.durationInput}
                        onFocus={e => e.target.select()}
                        onBlur={onBlur}
                        error={errors.inactivity?.duration}
                        data-testid="duration-input"
                      />
                    </>
                  )}
                />
                <select
                  value={alert.inactivity.unit}
                  onChange={editInactivityUnit}
                  className={styles.unitInput}
                  data-testid="unit-select"
                >
                  <option value="mins">mins</option>
                  <option value="hrs">hrs</option>
                  <option value="days">days</option>
                </select>
                <span>{alert.filterRules.length > 0 && "in which"}</span>
              </div>
              {alert.filterRules.map((rule, i) => (
                <div key={i} className={styles.filterRule} data-testid="filter-rule">
                  <Controller
                    control={control}
                    defaultValue={rule.path}
                    name={`filterRules[${i}].path`}
                    rules={{ required: "Cannot be empty" }}
                    render={({ ref, onChange, onBlur }) => (
                      <>
                        <TextInput
                          type="text"
                          ref={ref}
                          onChange={e => {
                            editFilterPath(i, e)
                            onChange(e)
                          }}
                          value={rule.path}
                          className={styles.pathInput}
                          onBlur={onBlur}
                          error={errors.filterRules?.[i]?.path}
                          placeholder="comma separated path"
                          data-testid="path-input"
                        />
                      </>
                    )}
                  />
                  <select
                    value={rule.operator}
                    onChange={e => editFilterOperator(i, e)}
                    data-testid="operator-select"
                  >
                    <option value="CONTAINS">contains</option>
                    <option value="NOT_CONTAINS">doesn't contain</option>
                  </select>
                  <div className={styles.wrapTogether}>
                    <Controller
                      control={control}
                      defaultValue={rule.value}
                      name={`filterRules[${i}].value`}
                      rules={{ required: "Cannot be empty", validate: isValidRegex }}
                      render={({ ref, onChange, onBlur }) => (
                        <>
                          <TextInput
                            type="text"
                            ref={ref}
                            onChange={e => {
                              editFilterValue(i, e)
                              onChange(e)
                            }}
                            value={rule.value}
                            className={styles.valueInput}
                            onBlur={onBlur}
                            error={errors.filterRules?.[i]?.value}
                            placeholder="regex (e.g. 'meiro\.io\/$')"
                            data-testid="value-input"
                          />
                        </>
                      )}
                    />
                    <Button
                      onClick={() => removeFilterRule(i)}
                      icon={["fas", "trash-alt"]}
                      color="red"
                      kind="text-only"
                      faded
                      data-testid="remove-rule-button"
                    />
                  </div>
                </div>
              ))}
              <Button
                onClick={addFilterRule}
                icon={["fas", "plus"]}
                color="grey_7"
                kind="secondary"
                size="sm"
              >
                add rule
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
