import React, { PropsWithChildren } from "react"
import styles from "./LandingLayout.module.scss"
import MeiroSymbol from "images/meiro-symbol-white-transparent.svg"

export default function LandingLayout({ children }: PropsWithChildren<{}>) {
  return (
    <div className={styles.page}>
      <div className={styles.formSection}>
        <img src={MeiroSymbol} alt="" className={styles.meiroSymbol} />
        {children}
      </div>
      <div className={styles.landingSection}>
        <h1>Meiro Events Dashboard</h1>
        <h2>All your events data under control.</h2>
      </div>
    </div>
  )
}
