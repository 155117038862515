import React from "react"
import styles from "./ChangePasswordModal.module.scss"
import { useForm } from "react-hook-form"
import Modal from "components/Modal/Modal"
import TextInput from "components/TextInput/TextInput"
import { FORM } from "consts"
import Button from "components/Button/Button"
import { isStrongPassword } from "helpers/validation"

export type FormData = {
  oldPassword: string
  newPassword: string
}

type ChangePasswordModalProps = {
  onClose: () => void
  onSubmit: (formData: FormData) => Promise<void>
}

export default function ChangePasswordModal({ onClose, onSubmit }: ChangePasswordModalProps) {
  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting },
  } = useForm({ mode: "onTouched" })

  return (
    <Modal className={styles.container} onClose={onClose} data-testid="change-pwd-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.header}>
          <div className={styles.title}>change password</div>
        </div>
        <TextInput
          name={`oldPassword`}
          ref={register({ required: FORM.FIELD_ERROR.REQUIRED })}
          label="Old password"
          reserveSpace
          error={errors.oldPassword}
          type="password"
          defaultValue=""
          autoComplete="old-password"
          className={styles.oldPasswordInput}
        />
        <TextInput
          name={`newPassword`}
          ref={register({
            required: FORM.FIELD_ERROR.REQUIRED,
            validate: isStrongPassword,
          })}
          label="New password"
          reserveSpace
          error={errors.newPassword}
          type="password"
          defaultValue=""
          autoComplete="new-password"
          showPasswordStrength
        />

        <div className={styles.buttons}>
          <div className={styles.expand}>
            <Button
              onClick={onClose}
              type="button"
              kind="text-only"
              color="grey_7"
              disabled={isSubmitting}
            >
              cancel
            </Button>
          </div>
          <Button type="submit" isLoading={isSubmitting}>
            save
          </Button>
        </div>
      </form>
    </Modal>
  )
}
