import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"
import { toast as _toast, ToastOptions } from "react-toastify"

const toast = {
  success: (message: ReactNode, options?: ToastOptions) =>
    _toast.success(
      <>
        <FontAwesomeIcon className="toastIcon" icon={["fas", "check"]} />
        {message}
      </>,
      options,
    ),
  warn: (message: ReactNode, options?: ToastOptions) =>
    _toast.warn(
      <>
        <FontAwesomeIcon className="toastIcon" icon={["fas", "exclamation-triangle"]} />
        {message}
      </>,
      { autoClose: false, ...options },
    ),
  info: (message: ReactNode, options?: ToastOptions) =>
    _toast.info(
      <>
        <FontAwesomeIcon className="toastIcon" icon={["fas", "info-circle"]} />
        {message}
      </>,
      options,
    ),
  error: (message: ReactNode, options?: ToastOptions) =>
    _toast.error(
      <>
        <FontAwesomeIcon className="toastIcon" icon={["fas", "exclamation-triangle"]} />
        {message}
      </>,
      { autoClose: false, ...options },
    ),
}

export default toast
