import Button from "components/Button/Button"
import Card from "components/Card/Card"
import Spinner from "components/Spinner/Spinner"
import { NOTIFS } from "consts"
import { prop, sortBy, without } from "ramda"
import React, { useCallback, useEffect, useState } from "react"
import Api from "services/api"
import toast from "services/toast"
import { User } from "types"
import styles from "./Trash.module.scss"
import useFetcher from "hooks/useFetcher"

export default function Trash() {
  const [users, setUsers] = useState<User[]>([])
  const sortedUsers = sortBy(prop("email"), users)
  const [userToRestore, setUserToRestore] = useState(users[0])
  const [isRestoring, setIsRestoring] = useState(false)
  const isEmpty = users.length === 0

  const { fetch: fetchUsers, isLoading } = useFetcher(Api.users.getDeletedUsers)

  useEffect(() => {
    fetchUsers().then(res => res && setUsers(res.users))
  }, [fetchUsers])

  const restoreUser = useCallback(async user => {
    setIsRestoring(true)
    setUserToRestore(user)
    const response = await Api.users.restoreUser(user)
    if (response) {
      toast.success(NOTIFS.SUCCESS.USER_RESTORED)
      setUsers(without([user]))
    }
    setIsRestoring(false)
  }, [])

  return (
    <>
      <Card className={styles.titleCard} bgColor="light_blue_bg">
        <div className={styles.title}>Trash</div>
      </Card>
      <Card className={styles.mainCard}>
        {isLoading && <Spinner className={styles.spinner} />}
        {!isLoading && isEmpty && (
          <div className={styles.emptyMessage}>There are no deleted users.</div>
        )}
        {!isLoading && !isEmpty && (
          <table className={styles.usersTable}>
            <thead>
              <tr>
                <th>e-mail</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map(user => (
                <tr key={user.id} data-testid="user-row">
                  <td>{user.email}</td>
                  <td>
                    <div className={styles.buttons}>
                      <Button
                        kind="text-only"
                        faded
                        color="grey_7"
                        icon={["fas", "trash-restore-alt"]}
                        onClick={() => restoreUser(user)}
                        className={styles.rowButton}
                        size="xl"
                        data-tooltip="Restore"
                        isLoading={isRestoring && userToRestore.id === user.id}
                        data-testid="restore-button"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Card>
    </>
  )
}
