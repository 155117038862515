import React from "react"
import styles from "./NotFound.module.scss"
import MeiroLogo from "images/meiro-logo.svg"

export default function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <span>4</span>
        <img src={MeiroLogo} alt="" className={styles.logo} />
        <span>4</span>
      </div>
      <div className={styles.message}>
        <h1 className={styles.title}>Oops… something went wrong.</h1>
        <div className={styles.subtitle}>The page you're looking for doesn't exist.</div>
      </div>
    </div>
  )
}
