import Button from "components/Button/Button"
import Card from "components/Card/Card"
import DefaultAvatar from "components/DefaultAvatar/DefaultAvatar"
import DialogModal from "components/DialogModal/DialogModal"
import EditableValue from "components/EditableValue/EditableValue"
import Spinner from "components/Spinner/Spinner"
import { NOTIFS, USERS_ENABLED } from "consts"
import { isValidEmail } from "helpers/validation"
import useFetcher from "hooks/useFetcher"
import NotFound from "pages/NotFound/NotFound"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import Api from "services/api"
import AuthService from "services/auth"
import { getRoutePath } from "services/routes"
import toast from "services/toast"
import { User } from "types"
import ChangePasswordModal, { FormData } from "./components/ChangePasswordModal/ChangePasswordModal"
import styles from "./UserDetail.module.scss"

export default function UserDetail() {
  const [user, setUser] = useState<User | null>(null)
  const match = useRouteMatch<{ id: string }>()
  const currentUser = AuthService.getCurrentUser()
  const id = match.path === getRoutePath("userSettings") ? currentUser?.user_id : match.params.id
  const isSelf = id === currentUser?.user_id
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const history = useHistory()

  const { fetch: fetchUser, isLoading, error: notFoundError } = useFetcher(
    useCallback(() => Api.users.getUserById(id!), [id]),
  )

  useEffect(() => {
    fetchUser().then(setUser)
  }, [fetchUser])

  const openDeleteModal = useCallback(user => setShowDeleteModal(true), [])
  const closeDeleteModal = useCallback(() => setShowDeleteModal(false), [])

  const confirmDelete = useCallback(async () => {
    setIsDeleting(true)
    const response = await Api.users.deleteUser(user!)
    if (response) {
      toast.success(NOTIFS.SUCCESS.USER_DELETED)
      history.push(getRoutePath("users"))
    } else {
      setIsDeleting(false)
      setShowDeleteModal(false)
    }
  }, [history, user])

  const openPasswordModal = useCallback(() => setShowPasswordModal(true), [])
  const closePasswordModal = useCallback(() => setShowPasswordModal(false), [])

  const submitPasswordChange = useCallback(
    async ({ oldPassword, newPassword }: FormData) => {
      try {
        const response = await Api.userAuth.changePassword(
          currentUser!.user_id,
          oldPassword,
          newPassword,
        )
        if (response) {
          toast.success(NOTIFS.SUCCESS.PASSWORD_CHANGED)
          setShowPasswordModal(false)
        }
      } catch {
        toast.error(NOTIFS.ERROR.WRONG_PASSWORD)
      }
    },
    [currentUser],
  )

  const changeEmail = useCallback(
    async email => {
      const response = await Api.users.modifyUser(id!, { email })
      if (response) {
        toast.success(NOTIFS.SUCCESS.USER_MODIFIED)
        setUser(response)
      }
    },
    [id],
  )

  if (notFoundError) {
    return <NotFound />
  }

  return (
    <>
      {showDeleteModal && (
        <DialogModal
          title="delete user"
          message={`Do you really want to delete user ${user!.email}?`}
          type="delete"
          isLoading={isDeleting}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
      )}
      {showPasswordModal && (
        <ChangePasswordModal onClose={closePasswordModal} onSubmit={submitPasswordChange} />
      )}
      <Card className={styles.titleCard} bgColor="light_blue_bg">
        <div className={styles.title}>User settings</div>
      </Card>
      <Card className={styles.mainCard}>
        {isLoading ? (
          <Spinner className={styles.spinner} />
        ) : (
          user && (
            <>
              <div className={styles.avatarWrapper}>
                <DefaultAvatar email={user.email} />
              </div>
              <div className={styles.field}>
                <div className={styles.key}>E-mail</div>
                <div className={styles.value}>
                  {USERS_ENABLED ? (
                    <EditableValue
                      initValue={user.email}
                      onChange={changeEmail}
                      validate={isValidEmail}
                      className={styles.value}
                    />
                  ) : (
                    user.email
                  )}
                </div>
              </div>
              {isSelf && !user.sso_enabled && USERS_ENABLED && (
                <Button icon={["fas", "lock-alt"]} onClick={openPasswordModal}>
                  change password
                </Button>
              )}
              {!isSelf && (
                <Button
                  icon={["fas", "trash-alt"]}
                  color="red"
                  kind="text-only"
                  faded
                  onClick={openDeleteModal}
                  size="xl"
                />
              )}
            </>
          )
        )}
      </Card>
    </>
  )
}
