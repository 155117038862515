import { sources } from "consts"
import useTypedSelector from "hooks/useTypedSelector"
import { partition } from "ramda"
import React, { useCallback } from "react"
import { Source } from "types"
import SourceOverview from "./components/SourceOverview/SourceOverview"
import styles from "./Dashboard.module.scss"

const isEvenIndex = (_: any, i: number) => i % 2 === 0
const isOddIndex = (_: any, i: number) => i % 2 !== 0

export default function Dashboard() {
  const events = useTypedSelector(state => state.events)
  const eventCounts = useTypedSelector(state => state.eventCounts)
  const [sourcesWithEvents, otherSources] = partition(
    (source: Source) => events[source].length > 0,
    sources,
  )

  const renderSource = useCallback(
    (source: Source) => (
      <SourceOverview
        key={source}
        sourceSlug={source}
        eventCounts={eventCounts[source]}
        events={events[source]}
        className={styles.sourceOverview}
      />
    ),
    [eventCounts, events],
  )

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        {sourcesWithEvents.filter(isEvenIndex).map(renderSource)}
        {otherSources.filter(isEvenIndex).map(renderSource)}
      </div>
      <div className={styles.column}>
        {sourcesWithEvents.filter(isOddIndex).map(renderSource)}
        {otherSources.filter(isOddIndex).map(renderSource)}
      </div>
    </div>
  )
}
