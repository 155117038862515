import alertActions from "store/alerts/alertActions"
import store from "store/store"
import { AccessToken } from "types"
import Api from "./api"
import SocketService from "./socket"
import toast from "./toast"
import { UserStorage } from "./userStorage"

function parseAccessToken(token: string): AccessToken | null {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (error) {
    console.error(error)
    toast.error("Error: invalid response from the server.")
    return null
  }
}

function login(accessToken: string) {
  const userAuth = parseAccessToken(accessToken)
  if (userAuth) {
    UserStorage.set({ ...userAuth, accessToken })
  }
}

async function credentialsLogin(email: string, password: string) {
  const response = await Api.userAuth.login(email, password)
  if (response) login(response.token)
  return response
}

async function oktaLogin(code: string) {
  const response = await Api.userAuth.oktaLogin(code)
  if (response) login(response.token)
  return response
}

async function logout() {
  if (UserStorage.get()) {
    await Api.userAuth.logout()
    UserStorage.remove()
  }
  SocketService.disconnect()
  store.dispatch(alertActions.wipeAlerts())
}

async function refreshToken() {
  const userAuth = UserStorage.get()
  if (!userAuth) {
    await logout()
    return false
  }

  const response = await Api.userAuth.refreshToken(userAuth.accessToken)
  if (response) {
    const userAuth = parseAccessToken(response.token)
    if (userAuth) {
      UserStorage.set({ ...userAuth, accessToken: response.token })
      return true
    }
    return false
  }
}

const AuthService = {
  login: credentialsLogin,
  oktaLogin,
  logout,
  refreshToken,
  getCurrentUser: UserStorage.get,
}

export default AuthService
