import React, { ComponentProps, useCallback, useEffect } from "react"
import classnames from "classnames"
import styles from "./Modal.module.scss"

type ModalProps = {
  onClose: () => void
} & ComponentProps<"div">

export default function Modal({ className, children, onClose, ...props }: ModalProps) {
  const escListener = useCallback(
    e => {
      if (e.key === "Escape") {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    window.addEventListener("keyup", escListener)
    return () => {
      window.removeEventListener("keyup", escListener)
    }
  }, [escListener])

  return (
    <>
      <div className={styles.clickCatcher} onClick={onClose} />
      <div className={classnames(styles.container, className)} {...props}>
        {children}
      </div>
    </>
  )
}
