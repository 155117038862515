import { combineReducers, createStore, Store } from "redux"
import { devToolsEnhancer } from "redux-devtools-extension"
import eventCounts from "./events/eventCountsReducer"
import events from "./events/eventsReducer"
import alerts from "./alerts/alertsReducer"
import alertEdits from "./alerts/alertEditsReducer"
import sourceAlertIds from "./alerts/sourceAlertIdsReducer"

const rootReducer = combineReducers({
  eventCounts,
  events,
  sourceAlertIds,
  alerts,
  alertEdits,
})

export type State = ReturnType<typeof rootReducer>

const store: Store<State> = createStore(rootReducer, devToolsEnhancer({}))

export default store
