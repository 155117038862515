import Spinner from "components/Spinner/Spinner"
import { STORAGE_KEYS } from "consts"
import { useEffect, useMemo, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import AuthService from "services/auth"
import { getRoutePath } from "services/routes"
import toast from "services/toast"
import styles from "./OktaCallback.module.scss"

export default function OktaCallback() {
  const location = useLocation()
  const params = useMemo(() => new URLSearchParams(location.search), [location.search])
  const code = params.get("code")
  const [isError, setIsError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    async function login() {
      const response = await AuthService.oktaLogin(code!)
      if (response) {
        window.localStorage.setItem(STORAGE_KEYS.TERMS_ACCEPTED, "true")
        const redirect = window.localStorage.getItem(STORAGE_KEYS.REDIRECT_AFTER_OKTA)
        if (redirect !== null && redirect !== "") {
          history.replace(redirect)
        } else {
          history.replace(getRoutePath("monitoring"))
        }
      } else {
        setIsError(true)
      }
    }

    if (code) {
      login()
    } else {
      const error = params.get("error_description")
      toast.error(error)
      setIsError(true)
    }
  }, [code, history, params])

  return (
    <div className={styles.container}>
      {isError ? (
        <div className={styles.message}>
          <h1>Oops, something went wrong.</h1>
          <p>
            Go back to <Link to={getRoutePath("login")}>login</Link>.
          </p>
        </div>
      ) : (
        <Spinner className={styles.spinner} size="lg" />
      )}
    </div>
  )
}
