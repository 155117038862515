import React, { useCallback, useMemo, useState } from "react"
import styles from "./SetPassword.module.scss"
import LandingLayout from "components/LandingLayout/LandingLayout"
import { getRoutePath } from "services/routes"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useForm } from "react-hook-form"
import Api from "services/api"
import toast from "services/toast"
import { FORM, NOTIFS } from "consts"
import TextInput from "components/TextInput/TextInput"
import Button from "components/Button/Button"
import MeiroSymbol from "images/meiro-symbol-white-transparent.svg"
import { isStrongPassword } from "helpers/validation"

type ResetToken = {
  domain: string
  email: string
  exp: number
}

export default function SetPassword() {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({ mode: "onTouched" })
  const history = useHistory()
  const { search } = useLocation()
  const { email, referrer, reset_token } = Object.fromEntries(new URLSearchParams(search).entries())
  const isInvite = referrer === "invitation"

  let parsedToken
  try {
    parsedToken = JSON.parse(atob(reset_token.split(".")[1])) as ResetToken
  } catch (error) {
    parsedToken = null
  }

  const isExpired = parsedToken && parsedToken.exp * 1000 - Date.now() < 0

  const setPassword = useCallback(
    async ({ password }) => {
      const response = await Api.userAuth.resetPassword(email!, password, reset_token!)
      if (response) {
        toast.success(isInvite ? NOTIFS.SUCCESS.ACCOUNT_CREATED : NOTIFS.SUCCESS.PASSWORD_SET)
        history.push(getRoutePath("login"))
      }
    },
    [email, history, isInvite, reset_token],
  )

  const [isResending, setIsResending] = useState(false)
  const sendResetEmail = useCallback(async () => {
    setIsResending(true)
    const response = await Api.userAuth.sendResetPasswordEmail(email!)
    if (response) {
      toast.success(NOTIFS.SUCCESS.FORGOT_PASSWORD_EMAIL_SENT, { autoClose: 6000 })
      history.push(getRoutePath("login"))
    }
    setIsResending(false)
  }, [email, history])

  const linkToLogin = useMemo(
    () => (
      <Link to={getRoutePath("login")} className={styles.linkUnderForm}>
        login
      </Link>
    ),
    [],
  )

  return (
    <>
      {!parsedToken && (
        <LandingLayout>
          <div className={styles.form}>
            <strong>Error: invalid link.</strong> Please contact your administrator.
          </div>
        </LandingLayout>
      )}
      {isExpired && (
        <div className={styles.background}>
          <img src={MeiroSymbol} alt="" className={styles.meiroSymbol} />
          <div className={styles.form}>
            {isInvite ? (
              <>
                <h1>Your invitation has expired 😞</h1>
                <div className={styles.instruction}>
                  Ask your administrator for a new invitation.
                </div>
              </>
            ) : (
              <>
                <h1>Your reset token has expired.</h1>
                <Button
                  fullWidth
                  onClick={sendResetEmail}
                  isLoading={isResending}
                  size="lg"
                  className={styles.resendButton}
                >
                  re-send e-mail
                </Button>
              </>
            )}
          </div>
          {linkToLogin}
        </div>
      )}
      {parsedToken && !isExpired && (
        <LandingLayout>
          <form className={styles.form} onSubmit={handleSubmit(setPassword)}>
            <h1>{isInvite ? "You've been invited" : "Set new password"}</h1>
            <div className={styles.subtitle}>
              <span>E-mail:</span> {email}
            </div>
            {isInvite && <div className={styles.instruction}>Set a password:</div>}
            <TextInput
              type="password"
              name="password"
              label="Password"
              className={styles.input}
              ref={register({
                required: FORM.FIELD_ERROR.REQUIRED,
                validate: isStrongPassword,
              })}
              reserveSpace
              error={errors.password}
              size="lg"
              autoComplete="new-password"
              showPasswordStrength
            />
            <Button fullWidth type="submit" isLoading={isSubmitting} size="lg">
              {isInvite ? "create account" : "set password"}
            </Button>
          </form>
          {linkToLogin}
        </LandingLayout>
      )}
    </>
  )
}
