import React, { useCallback, useEffect, useState } from "react"
import { Alert, Source } from "types"
import { useDispatch } from "react-redux"
import useTypedSelector from "hooks/useTypedSelector"
import { equals } from "ramda"
import EventAlert from "../EventAlert/EventAlert"
import Button from "components/Button/Button"
import DialogModal from "components/DialogModal/DialogModal"
import Api from "services/api"
import alertActions from "store/alerts/alertActions"
import { isTempId, makeTempId } from "helpers/tempId"
import toast from "services/toast"
import { NOTIFS } from "consts"

type EventAlertWrapperProps = {
  source: Source
  eventType: string
  alertIdToHighlight?: string
}

export default function EventAlertWrapper({
  source,
  eventType,
  alertIdToHighlight,
}: EventAlertWrapperProps) {
  const dispatch = useDispatch()

  const alertIds = useTypedSelector(state => state.sourceAlertIds[source][eventType], equals)
  const alertsState = useTypedSelector(state => state.alerts)
  const alertEditsState = useTypedSelector(state => state.alertEdits)
  const alerts = (alertIds || []).map(id => alertEditsState[id] ?? alertsState[id]) as Alert[]

  const [isDeleting, setIsDeleting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const indexToHighlight = alerts.findIndex(({ id }) => id === alertIdToHighlight)
  const [currentIndex, _setIndex] = useState(-1)
  const [hasSwitched, setHasSwitched] = useState(false)
  const currentAlert = alerts[currentIndex] ?? null

  useEffect(() => {
    if (alertIds && !hasSwitched) {
      _setIndex(indexToHighlight > -1 ? indexToHighlight : alerts.length - 1)
    }
  }, [alertIds, alerts.length, hasSwitched, indexToHighlight])

  const setIndex = useCallback(s => {
    setHasSwitched(true)
    _setIndex(s)
  }, [])

  const switcherData = {
    index: currentIndex,
    alerts: alerts.map(({ id }) => ({ isUnsaved: Boolean(alertEditsState[id] || isTempId(id)) })),
  }

  const addNewAlert = useCallback(() => {
    setIndex(alerts.length)
    dispatch(
      alertActions.addNewAlert({
        id: makeTempId(),
        source,
        eventType,
        inactivity: { duration: 1, unit: "hrs" },
        emails: [],
        filterRules: [],
      }),
    )
  }, [alerts.length, dispatch, eventType, setIndex, source])

  const cancelNewAlert = useCallback(() => {
    if (!currentAlert || !isTempId(currentAlert.id)) return
    dispatch(alertActions.deleteAlert(currentAlert))
    if (currentIndex === alerts.length - 1) {
      setIndex(alerts.length - 2)
    }
  }, [alerts.length, currentAlert, currentIndex, dispatch, setIndex])

  const openDeleteModal = useCallback(() => {
    if (!currentAlert || isTempId(currentAlert.id)) return
    setShowDeleteModal(true)
  }, [currentAlert])

  const confirmDelete = useCallback(async () => {
    setIsDeleting(true)
    const response = await Api.alerts.deleteAlert(currentAlert!)
    if (response) {
      dispatch(alertActions.deleteAlert(currentAlert!))
      toast.success(NOTIFS.SUCCESS.ALERT_DELETED)
      if (currentIndex === alerts.length - 1) {
        setIndex(alerts.length - 2)
      }
    }
    setIsDeleting(false)
    setShowDeleteModal(false)
  }, [currentAlert, dispatch, currentIndex, alerts.length, setIndex])

  const closeDeleteModal = useCallback(() => setShowDeleteModal(false), [])

  const saveAlert = useCallback(async () => {
    if (!currentAlert) return
    if (isTempId(currentAlert.id)) {
      const response = await Api.alerts.createAlert(currentAlert)
      if (response) {
        dispatch(alertActions.saveCreatedAlert({ tempId: currentAlert.id, alert: response }))
        toast.success(NOTIFS.SUCCESS.ALERT_CREATED)
      }
    } else {
      const response = await Api.alerts.modifyAlert(currentAlert)
      if (response) {
        dispatch(alertActions.saveModifiedAlert(currentAlert))
        toast.success(NOTIFS.SUCCESS.ALERT_MODIFIED)
      }
    }
  }, [currentAlert, dispatch])

  const undoChanges = useCallback(() => {
    if (!currentAlert) return
    dispatch(alertActions.undoChanges({ id: currentAlert.id }))
  }, [currentAlert, dispatch])

  const editAlert = useCallback((alert: Alert) => dispatch(alertActions.editAlert(alert)), [
    dispatch,
  ])

  return (
    <>
      {showDeleteModal && (
        <DialogModal
          title="delete alert"
          message={`Do you really want to delete alert ${currentIndex + 1}?`}
          type="delete"
          isLoading={isDeleting}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
      )}
      <EventAlert
        key={currentAlert?.id ?? "null"}
        alert={currentAlert}
        switcherData={switcherData}
        onSwitch={setIndex}
        onAddNewAlert={addNewAlert}
        onCancelNewAlert={cancelNewAlert}
        onDeleteAlert={openDeleteModal}
        onSaveAlert={saveAlert}
        onUndoChanges={undoChanges}
        onEditAlert={editAlert}
        highlightAlert={Boolean(
          !hasSwitched && alertIdToHighlight && alertIdToHighlight === currentAlert?.id,
        )}
      />
    </>
  )
}
