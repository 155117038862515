import Layout from "components/Layout/Layout"
import Spinner from "components/Spinner/Spinner"
import Alerts from "pages/Alerts/Alerts"
import Dashboard from "pages/Dashboard/Dashboard"
import NotFound from "pages/NotFound/NotFound"
import SourceDetail from "pages/SourceDetail/SourceDetail"
import Trash from "pages/Trash/Trash"
import UserDetail from "pages/UserDetail/UserDetail"
import Users from "pages/Users/Users"
import React, { useCallback, useEffect, useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import AuthService from "services/auth"
import history from "services/history"
import { getRoutePath } from "services/routes"
import SocketService from "services/socket"
import styles from "./AuthenticatedRoutes.module.scss"
import { USERS_ENABLED } from "consts"

export default function AuthenticatedRoutes() {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true)

  const refreshTokenOrLogOut = useCallback(async () => {
    if (await AuthService.refreshToken()) {
      return true
    }

    await AuthService.logout()
    history.push(getRoutePath("login"))

    return false
  }, [])

  useEffect(() => {
    setIsCheckingAuth(true)

    // Use refresh token endpoint to verify authentication
    refreshTokenOrLogOut().then(didRefreshToken => {
      if (didRefreshToken) {
        setIsCheckingAuth(false)
        SocketService.connect()
      }
    })

    // Refresh token every 15 minutes
    const interval = setInterval(refreshTokenOrLogOut, 15 * 60 * 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isCheckingAuth ? (
    <div className={styles.spinnerContainer}>
      <Spinner />
    </div>
  ) : (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Redirect to={getRoutePath("monitoring")} />
        </Route>
        <Route path={getRoutePath("monitoring.alerts")} component={Alerts} />
        <Route path={getRoutePath("monitoring.detail")} component={SourceDetail} />
        <Route path={getRoutePath("monitoring")} component={Dashboard} />
        <Route path={getRoutePath("users.detail")} component={UserDetail} />
        {USERS_ENABLED && <Route path={getRoutePath("users")} component={Users} />}
        <Route path={getRoutePath("userSettings")} component={UserDetail} />
        <Route path={getRoutePath("trash")} component={Trash} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  )
}
