import { sources } from "consts"
import { isTempId } from "helpers/tempId"
import {
  append,
  assocPath,
  concat,
  curry,
  dissocPath,
  filter,
  groupBy,
  lensPath,
  map,
  mapObjIndexed as mapObj,
  mergeDeepWith,
  over,
  prop,
  set,
  without,
} from "ramda"
import { Alert, Source } from "types"
import { AlertsAction, ALERT_ACTION_TYPES } from "./alertActions"

type AlertId = Alert["id"]

const mergeAlertsFromAPIToState = curry(function (
  alerts: Alert[],
  sourceState: {
    [eventType: string]: string[]
  },
) {
  const alertsByEventType = groupBy(prop("eventType"), alerts)
  const alertIdsByEventType = mapObj(map(prop("id")), alertsByEventType)
  const unsavedAlerts = mapObj(filter(isTempId), sourceState)
  // Alerts that haven't been saved to API yet are kept aside, the rest of the state is overwritten
  // with new API data, then the unsaved alerts are concatted to the end
  const newSourceState = mergeDeepWith(concat, alertIdsByEventType, unsavedAlerts)
  return newSourceState
})

export type SourceAlertIdsState = {
  [K in Source]: {
    [eventType: string]: AlertId[]
  }
}

const initSourceAlertIds = Object.fromEntries(
  sources.map<[Source, { [eventType: string]: AlertId[] }]>(sourceSlug => [sourceSlug, {}]),
) as SourceAlertIdsState

export default function sourceAlertIds(
  state: SourceAlertIdsState = initSourceAlertIds,
  action: AlertsAction,
): SourceAlertIdsState {
  switch (action.type) {
    case ALERT_ACTION_TYPES.ADD_NEW_ALERT: {
      const { id, source, eventType } = action.payload
      return over(lensPath([source, eventType]), append(id), state)
    }
    case ALERT_ACTION_TYPES.SAVE_CREATED_ALERT: {
      const { tempId, alert } = action.payload
      const { source, eventType } = alert
      const index = state[source][eventType]!.indexOf(tempId)
      return index > -1 ? assocPath([source, eventType, index], alert.id, state) : state
    }
    case ALERT_ACTION_TYPES.SAVE_MODIFIED_ALERT: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.UNDO_CHANGES: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.EDIT_ALERT: {
      return state // noop
    }
    case ALERT_ACTION_TYPES.DELETE_ALERT: {
      const { id, source, eventType } = action.payload
      const newAlerts = without([id], state[source][eventType])
      return newAlerts.length === 0
        ? dissocPath([source, eventType], state)
        : set(lensPath([source, eventType]), newAlerts, state)
    }
    case ALERT_ACTION_TYPES.SET_ALERTS: {
      const { alerts } = action.payload
      const alertsBySource = groupBy(prop("source"), alerts)
      return mapObj(
        (sourceState, source) =>
          mergeAlertsFromAPIToState(alertsBySource[source] ?? [], sourceState),
        state,
      )
    }
    case ALERT_ACTION_TYPES.WIPE_ALERTS: {
      return initSourceAlertIds
    }
    default:
      return state
  }
}
