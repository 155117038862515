import React, { useCallback, useEffect } from "react"
import { Link, Redirect, useHistory, useLocation } from "react-router-dom"
import { Location } from "history"
import LoginForm from "./components/LoginForm/LoginForm"
import { getRoutePath } from "services/routes"
import AuthService from "services/auth"
import styles from "./Login.module.scss"
import LandingLayout from "components/LandingLayout/LandingLayout"
import { STORAGE_KEYS } from "consts"
import Api from "services/api"
import Spinner from "components/Spinner/Spinner"
import useFetcher from "hooks/useFetcher"

export default function Login() {
  const hasAgreedToTerms = window.localStorage.getItem(STORAGE_KEYS.TERMS_ACCEPTED)
  const history = useHistory()
  const location = useLocation<{ from: Location } | undefined>()
  const redirectTo = location.state?.from

  const { fetch: fetchAuthOptions, isLoading, error, data: authOptions } = useFetcher(
    Api.userAuth.authOptions,
  )

  useEffect(() => {
    fetchAuthOptions()
  }, [fetchAuthOptions])

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await AuthService.login(email, password)
      if (response) {
        if (!hasAgreedToTerms) window.localStorage.setItem(STORAGE_KEYS.TERMS_ACCEPTED, "true")
        history.push(redirectTo ?? getRoutePath("monitoring"))
      }
    },
    [hasAgreedToTerms, history, redirectTo],
  )

  const loginWithOkta = useCallback(async () => {
    if (redirectTo) {
      window.localStorage.setItem(
        STORAGE_KEYS.REDIRECT_AFTER_OKTA,
        redirectTo.pathname + redirectTo.search,
      )
    }
    if (authOptions?.okta_auth_link) {
      window.location.href = authOptions.okta_auth_link
    }
  }, [authOptions, redirectTo])

  const isLoggedIn = Boolean(AuthService.getCurrentUser())
  if (isLoggedIn) {
    return <Redirect to={getRoutePath("monitoring")} />
  }

  return (
    <LandingLayout>
      {(isLoading || error) && (
        <div className={styles.messageContainer}>
          {error ? (
            <div className={styles.message}>
              <h1>Oops, something went wrong.</h1>
              <div>Please try refreshing the page.</div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      )}
      {!isLoading && !error && (
        <LoginForm
          onLogin={login}
          onLoginWithOkta={loginWithOkta}
          askForAgreement={!hasAgreedToTerms}
          basicLoginEnabled={authOptions?.is_credentials_login_enabled}
          oktaLoginEnabled={authOptions?.is_okta_login_enabled}
        />
      )}
      <Link to={getRoutePath("password.reset")} className={styles.linkUnderForm}>
        Forgot password
      </Link>
    </LandingLayout>
  )
}
