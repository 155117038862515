import React, { ComponentProps, useCallback, useState } from "react"
import classnames from "classnames"
import styles from "./EventTypeRow.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useToggle from "hooks/useToggle"
import { format, formatDistanceToNowStrict } from "date-fns"
import { FORMAT } from "consts"
import { EventTypeEvents } from "types"
import { add } from "ramda"
import PayloadModal from "components/PayloadModal/PayloadModal"

type EventTypeRowProps = { events: EventTypeEvents } & ComponentProps<"div">

export default function EventTypeRow({
  className,
  events: { eventType, events, lastTimestamp },
}: EventTypeRowProps) {
  const [isExpanded, toggleIsExpanded] = useToggle(false)
  const [showCount, setShowCount] = useState(5)
  const [showPaylodModal, setShowPayloadModal] = useState(false)
  const [payloadToShow, setPayloadToShow] = useState("")
  const shownEvents = events.slice(0, showCount)
  const displayShowMoreButton = events.length > showCount && showCount < 30

  const showMore = useCallback(() => setShowCount(add(5)), [])
  const showPayload = useCallback(payload => {
    setPayloadToShow(payload)
    setShowPayloadModal(true)
  }, [])
  const closeModal = useCallback(() => setShowPayloadModal(false), [])

  return (
    <div className={classnames(styles.container, className)}>
      {showPaylodModal && <PayloadModal payload={payloadToShow} onClose={closeModal} />}
      <div className={styles.header} onClick={toggleIsExpanded} data-testid="event-row-header">
        <div className={styles.title}>
          {eventType}
          <FontAwesomeIcon
            icon={["fas", "caret-down"]}
            flip={isExpanded ? "vertical" : undefined}
            className={styles.caretIcon}
          />
        </div>
        <span>{formatDistanceToNowStrict(lastTimestamp)} ago</span>
      </div>
      {isExpanded && (
        <>
          {shownEvents.map(({ payload, timestamp }) => (
            <div key={JSON.stringify(payload)} className={styles.eventRow}>
              <div className={styles.expand}>
                <button
                  className={styles.showPayloadButton}
                  onClick={() => showPayload(payload)}
                  data-testid="show-payload-button"
                >
                  Show payload
                </button>
              </div>
              <span>{format(timestamp, FORMAT.EVENT_TIME_DATE)}</span>
            </div>
          ))}
          {displayShowMoreButton && (
            <button
              className={styles.showMoreButton}
              onClick={showMore}
              data-testid="show-more-button"
            >
              Show more
              <FontAwesomeIcon icon={["fas", "caret-down"]} className={styles.caretIcon} />
            </button>
          )}
        </>
      )}
    </div>
  )
}
