import React, { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from "react"
import classnames from "classnames"
import styles from "./Expander.module.scss"

type ExpanderProps = {
  isExpanded: boolean
} & ComponentProps<"div">

export default function Expander({ className, children, isExpanded }: ExpanderProps) {
  const contentRef = useRef<HTMLDivElement>(null)
  const getContentHeight = useCallback(() => contentRef.current?.getBoundingClientRect().height, [])
  const [wrapperHeight, setWrapperHeight] = useState(getContentHeight())
  const setHeight = useCallback(() => setWrapperHeight(getContentHeight()), [getContentHeight])

  // For newer browsers
  const resizeObserver = useMemo(
    () =>
      ResizeObserver
        ? new ResizeObserver((entries: any[]) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            for (const _ of entries) {
              setHeight()
            }
          })
        : null,
    [setHeight],
  )

  // Fallback for older browsers
  const mutationObserver = useMemo(() => new MutationObserver(setHeight), [setHeight])

  useEffect(() => {
    if (contentRef.current) {
      if (resizeObserver) {
        resizeObserver.observe(contentRef.current)
      } else {
        mutationObserver.observe(contentRef.current, {
          subtree: true,
          childList: true,
          attributes: true,
        })
        window.addEventListener("resize", setHeight)
      }
    }

    if (wrapperHeight !== getContentHeight()) {
      setHeight()
    }

    const timeout = setTimeout(() => {
      if (wrapperHeight !== getContentHeight()) {
        setHeight()
      }
    }, 100)

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      } else {
        mutationObserver.disconnect()
        window.removeEventListener("resize", setHeight)
      }
      clearTimeout(timeout)
    }
  }, [resizeObserver, getContentHeight, wrapperHeight, children, setHeight, mutationObserver])

  return (
    <div
      className={classnames(styles.container, className)}
      style={{ height: isExpanded ? wrapperHeight : 0 }}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  )
}
